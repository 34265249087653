import Api from '../../Api'
import { dateFormat, dureeFormat } from '../../Helpers'
import { ROLES, ROUTES } from '../../../constants'
import labels from '../../Common/translations'
// import React from 'react'
// import { Progress } from 'semantic-ui-react'

export default {
   endpoint: 'companies',
   pagesize: 10,
   defaultSorting: { field: 'createdAt', order: 'desc' },
   columns: {
      name: {
         label: labels.fields.name,
         accessor: s => `${s.name}`,
         options: {
            maxWidth: '150px',
         },
      },
      seats: {
         label: labels.fields.users_total,
         accessor: ({ users }) =>
            `${(users && users.total) || 0}${
               users && users.purchased > 0 ? `/${users.purchased}` : ''
            }`,
         options: {
            maxWidth: '25px',
         },
      },
      actives: {
         label: labels.fields.users_active,
         accessor: ({ users }) =>
            `${(users && users.active) || 0}${
               users && users.maxActive > 0 ? `/${users.maxActive}` : ''
            }`,
         options: {
            maxWidth: '25px',
         },
      },
      minutes: {
         label: labels.fields.video_quota,
         accessor: s =>
            s.seconds
               ? `${dureeFormat(s.seconds.used * 1000) || 0} / ${s.seconds
                    .purchased / 60 || 0}mn`
               : '0 / 0',
      },
      createdAt: {
         label: labels.fields.created,
         accessor: s => dateFormat(s.createdAt),
         options: {
            maxWidth: '150px',
         },
      },
      partner: {
         label: labels.fields.partner,
         accessor: s => s.partner && s.partner.label,
         options: {
            maxWidth: '120px',
         },
      },
      allowPartnerAccess: {
         label: labels.fields.data_access,
         accessor: s =>
            s.partner && s.partner.ref
               ? s.allowPartnerAccess === true
                  ? '\u{2714}'
                  : '\u{1F6AB}'
               : '',
         options: {
            center: true,
            width: '120px',
            allowOverflow: true,
         },
      },
   },
   buttons: {
      show: {
         label: labels.fields.show,
         url: ROUTES.COMPANY_SHOW,
         options: {
            icon: 'eye',
            positive: true,
         },
      },
      modify: {
         label: labels.fields.modify,
         url: ROUTES.COMPANY_MODIFY,
         options: {
            icon: 'edit',
            primary: true,
         },
      },
      groups: {
         label: labels.fields.group,
         url: ROUTES.COMPANY_GROUPS,
         options: {
            icon: 'group',
            primary: true,
         },
      },
      purchases: {
         label: labels.fields.purchases,
         url: ROUTES.COMPANY_PURCHASES,
         hidden: (data, { authUser }) => authUser.role !== ROLES.ROLE_ROOT,
         options: {
            icon: 'dollar',
            primary: true,
         },
      },
   },
   actions: {
      list: params => {
         return Api.instance().get('companies', params)
      },
   },
}
