import { dureeFormat, dateFormat, download } from '../../Helpers'
import * as ROUTES from '../../../constants/routes'
import Api from '../../Api'
import labels from '../../Common/translations'
import * as prettyBytes from 'pretty-bytes'

export default {
   endpoint: 'videos',
   pagesize: 10,
   defaultSorting: { field: 'createdAt', order: 'desc' },
   columns: {
      name: {
         label: labels.fields.name,
         accessor: s => `${s.name}`,
      },
      company: {
         label: labels.fields.company,
         accessor: s => `${s.company ? s.company.label : ''}`,
         hidden: ({ authUser }) => authUser.role === 'admin',
      },
      duration: {
         label: labels.fields.duration,
         accessor: s => (s.duration ? dureeFormat(s.duration * 1000) : ''),
         options: {
            maxWidth: '25px',
         },
      },
      resolution: {
         label: labels.fields.resolution,
         format: ({ width, height }) => (width ? `${width}x${height}` : ''),
         options: {
            maxWidth: '25px',
         },
      },
      size: {
         label: labels.fields.size,
         accessor: s => (s.size ? prettyBytes(parseInt(s.size)) : ''),
         options: {
            maxWidth: '25px',
         },
      },
      createdAt: {
         label: labels.fields.created_at,
         format: row => dateFormat(row.createdAt),
         sortable: true,
         options: {
            maxWidth: '150px',
         },
      },
   },
   buttons: {
      show: {
         label: labels.fields.show,
         url: ROUTES.VIDEO_SHOW,
         options: {
            icon: 'eye',
            positive: true,
         },
         disabled: data => data.pending === true,
      },
      modify: {
         label: labels.fields.modify,
         url: ROUTES.VIDEO_MODIFY,
         options: {
            icon: 'edit',
            positive: true,
         },
         disabled: data => data.pending === true,
      },
      vecto: {
         label: labels.fields.vecto,
         url: ROUTES.VIDEO_VECTO,
         options: {
            icon: 'microchip',
            positive: true,
         },
         disabled: data => data.pending === true,
      },
      download: {
         action: data => download(data.ref, data.name),
         label: labels.fields.downloads,
         options: {
            icon: 'download',
            primary: true,
         },
         disabled: data => data.pending === true,
      },
   },
   actions: {
      list: params => Api.instance().get('videos360', params),
   },
}
