import React from 'react'
import { withRouter } from 'react-router-dom'
import { withAuthentication } from '../Session'
import { compose } from 'recompose'
import FormComponent from '../Form'
import { Container, Sticky, Ref } from 'semantic-ui-react'
import vectoForm from './config/vecto-form'
import videoForm from './config/video-form'
import ReactPlayer from 'react-player'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { withFirebase } from '../Firebase'
import { AutoHeader, ErrorMessage } from '../Common'

const Range = Slider.Range

class VideoVectoPage extends React.Component {
   elements = {}

   constructor(props) {
      super(props)
      this.state = {
         ...props.location.state,
         loading: true,
         volume: 0.8,
         muted: false,
         played: 0,
         loaded: 0,
         duration: 0,
         min: 0,
         max: 0,
         value: [0, 0],
      }

      this.player = React.createRef()
      this.form = React.createRef()
      this.contextRef = React.createRef()
   }

   async componentDidMount() {
      this.setState({ loading: true })
      const { data: item } = await videoForm.actions.read(
         this.props.match.params.id,
      )
      this.setState({
         item,
         duration: item.file.duration || 0,
         url: await this.props.firebase.storage
            .ref(item.file.ref)
            .getDownloadURL(),
      })
      // offsets must be set here so they are not empty values and can be changed
      this.form.current.setState({
         data: {
            ...this.form.current.state.data,
            offsetStart: '0',
            offsetEnd: '0',
         },
      })
   }

   onSliderChange = value => {
      let { offsetStart, offsetEnd } = this.form.current.state.data
      let seek = 0
      if (value[0] !== parseInt(offsetStart)) {
         offsetStart = seek = Math.round(value[0])
      } else {
         offsetEnd = Math.round(value[1] - this.state.max)
         seek = value[1]
      }
      this.form.current.setState({
         data: {
            ...this.form.current.state.data,
            offsetStart,
            offsetEnd,
            duration: Math.round(
               this.state.max - value[0] - (this.state.max - value[1]),
            ),
         },
      })
      this.player.current.seekTo(seek)
   }

   onOffsetChange = e => {}

   onReady = () => {
      const max = Math.round(this.player.current.getDuration())
      if (this.state.loading === true) {
         this.form.current.setData('duration', max)
      }
      this.setState({
         loading: false,
         max,
         value: [0, max],
      })
   }

   render() {
      if (!this.state.item) {
         return <div />
      }

      return (
         <Ref innerRef={this.contextRef}>
            <Container>
               <Sticky context={this.contextRef}>
                  <Container style={styles.container}>
                     <AutoHeader params={[this.state.item.name]} />
                  </Container>{' '}
               </Sticky>
               {this.state.error && <ErrorMessage content={this.state.error} />}
               <Container>
                  <ReactPlayer
                     ref={this.player}
                     url={this.state.url}
                     width="100%"
                     height="100%"
                     preload="true"
                     onReady={this.onReady}
                     onError={err =>
                        this.setState({ error: err.target.error.message })
                     }
                     controls={false}
                  />
                  <Container style={styles.container}>
                     {this.state.loading === false && (
                        <Range
                           allowCross={false}
                           min={this.state.min}
                           max={this.state.max}
                           steps={this.state.max}
                           defaultValue={[this.state.min, this.state.max]}
                           dots
                           onChange={this.onSliderChange}
                        />
                     )}
                  </Container>
                  <FormComponent
                     form={vectoForm}
                     layout="default"
                     ref={this.form}
                     {...this.props}
                     video={this.state.item}
                  />
               </Container>
            </Container>
         </Ref>
      )
   }
}

const styles = {
   container: {
      padding: 5,
      margin: '10px',
   },
}

export default compose(
   withRouter,
   withAuthentication,
   withFirebase,
)(VideoVectoPage)
