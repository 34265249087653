import { dateFormat } from '../../Helpers'
import * as ROUTES from '../../../constants/routes'
import Api from '../../Api'
import * as ROLES from '../../../constants/roles'
import labels from '../../Common/translations'

// User may see other users from their perimeter in list
// but not access any function if their role is lower
const rolesHierarchy = [
   'viewer',
   'editor',
   'manager',
   'admin',
   'partner',
   'root',
]
const disableButton = (role, rowRole) =>
   rolesHierarchy.indexOf(rowRole) > rolesHierarchy.indexOf(role)

export default {
   endpoint: 'users',
   pagesize: 10,
   defaultSorting: { field: 'email', order: 'asc' },
   columns: {
      email: {
         label: labels.fields.email,
         options: {
            maxWidth: '25%',
         },
      },
      lastname: {
         label: labels.fields.lastname,
         options: {
            maxWidth: '10%',
         },
      },
      firstname: {
         label: labels.fields.firstname,
         options: {
            maxWidth: '10%',
         },
      },
      company: {
         label: labels.fields.company,
         accessor: s => `${s.company ? s.company.label : ''}`,
         hidden: ({ authUser }) =>
            !ROLES.ROLES_WITH_COMPANIES.includes(authUser.role),
         options: {
            maxWidth: '15%',
         },
      },
      lastLoginAt: {
         label: labels.fields.last_login,
         format: row => dateFormat(row.lastLoginAt),
         options: {
            maxWidth: '10%',
         },
      },
      role: {
         label: labels.fields.role,
         values: {
            source: () => Api.selectValues('roles', { readOnly: true }),
         },
         options: {
            maxWidth: '10%',
         },
      },
      status: {
         label: labels.fields.disabled_access,
         accessor: ({ status }) =>
            status === 'disabled' ? '\u{1F6AB}' : '\u{2714}',
         options: {
            center: true,
            width: '120px',
            allowOverflow: true,
         },
      },
   },
   buttons: {
      show: {
         label: labels.fields.show,
         url: ROUTES.USER_SHOW,
         options: {
            icon: 'eye',
            positive: true,
         },
         disabled: ({ _role }, { authUser }) =>
            disableButton(authUser.role, _role),
      },
      modify: {
         label: labels.fields.modify,
         url: ROUTES.USER_MODIFY,
         options: {
            icon: 'edit',
            primary: true,
         },
         disabled: ({ _role }, { authUser }) =>
            disableButton(authUser.role, _role),
      },
      resendEmail: {
         label: labels.fields.password_reset,
         options: {
            icon: 'mail outline',
            negative: true,
         },
         // passed params are current record data and page props
         action: ({ email }, { firebase }) => {
            firebase
               .doPasswordReset(email)
               .then(() =>
                  alert(`Password reset email successfully sent to ${email}`)
               )
               .catch(err =>
                  alert(
                     `Unable to send password reset email to '${email}': ${err.message}`
                  )
               )
         },
         disabled: ({ _role }, { authUser }) =>
            disableButton(authUser.role, _role),
      },
   },
   actions: {
      list: params => {
         params.q = params.q || {}
         params.q.status = [['active', 'disabled']]
         return Api.instance().get('users', params)
      },
   },
}
