import { dateFormat, labelFormat } from '../../Helpers'
import { DateTime } from 'luxon'
import vectoStatus from '../../../models/vecto-status'
import labels from '../../Common/translations'

export default {
   pagesize: 20,
   columns: {
      ts: {
         label: labels.fields.date,
         accessor: ({ ts }) =>
            dateFormat(ts, DateTime.DATETIME_MED_WITH_SECONDS),
         options: {
            maxWidth: '15%',
         },
      },
      status: {
         label: labels.fields.new_status,
         accessor: s => labelFormat(s.status, vectoStatus),
         options: {
            maxWidth: '20%',
         },
      },
      worker: {
         label: labels.fields.worker,
         options: {
            maxWidth: '50%',
         },
      },
      os: {
         label: labels.fields.operating_system,
         options: {
            maxWidth: '10%',
         },
      },
   },
}
