import { dureeFormat, dateFormat } from '../../Helpers'
import Api from '../../Api'
import * as ROUTES from '../../../constants/routes'
import labels from '../../Common/translations'

export default {
   endpoint: 'videos/*/vectos',
   // where to find the parent id
   pid: 'video.ref',
   pagesize: 10,
   defaultSorting: { field: 'createdAt', order: 'desc' },
   columns: {
      name: {
         label: labels.fields.name,
         accessor: s => `${s.name}`,
      },
      company: {
         label: labels.fields.company,
         accessor: ({ company }) => `${company ? company.label : ''}`,
         hidden: ({ authUser }) => authUser.role === 'admin',
         options: {
            maxWidth: '20px',
         },
      },
      createdBy: {
         label: labels.fields.created_by,
         accessor: s => `${s.createdBy ? s.createdBy.label : ''}`,
      },
      duration: {
         label: labels.fields.length,
         accessor: ({ duration }) =>
            duration ? dureeFormat(duration * 1000, true) : '?',
         options: {
            maxWidth: '20px',
         },
      },
      createdAt: {
         label: labels.fields.created_at,
         accessor: ({ createdAt }) => dateFormat(createdAt, false),
         sortable: true,
         options: {
            maxWidth: '25px',
         },
      },
   },
   buttons: {
      show: {
         label: labels.fields.shares,
         url: ROUTES.SCENE_SHOW,
         options: {
            icon: 'eye',
            positive: true,
         },
      },
      delete: {
         label: labels.fields.delete,
         options: {
            icon: 'delete',
            negative: true,
         },
         // passed params are current record data and page props
         action: ({ uid, video }) => {
            const uri = video ? `${video.ref}/vectos/${uid}` : `vectos/${uid}`
            Api.instance()
               .delete(uri)
               .then(() => window.location.reload())
               .catch(err => err.message)
         },
      },
   },
   actions: {
      list: params => {
         return Api.instance().get('videos3d', params)
      },
   },
}
