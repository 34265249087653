export default {
   unknown: {
      es: '???',
      fr: '???',
      en: '???',
   },
   errors: {
      error: {
         es: 'Ocurrió un error',
         fr: 'Une erreur est survenue',
         en: 'An error occurred',
      },
      data: {
         es:
            'Se produjo un error al acceder a los datos, por favor comuníquese con el administrador (% 0)',
         en:
            'An error occurred while accessing data, please contact an administrator (%0)',
         fr:
            "Une erreur s'est produite lors de l'accès aux données,\nveuillez contacter un administrateur (%0)",
      },
      noData: {
         es: 'No hay datos para visualizar',
         fr: `Il n'y a pas de données à afficher`,
         en: 'There is no data to display',
      },
      userNotFound: {
         es: 'Usuario no encontrado',
         en: 'User not found',
         fr: 'Utilisateur non trouvé',
      },
      wrongPassword: {
         es: 'Contraseña errónea',
         en: 'Wrong password',
         fr: 'Mot de passe erroné',
      },
      passwordsMismatch: {
         es: 'Las contraseñas no coinciden',
         en: 'Passwords mismatch',
         fr: 'Les mots de passe ne correspondent pas',
      },
      errorInvitedUser: {
         es:
            'El usuario ya existe. Por favor, compruebe la dirección de correo electrónico',
         en: 'User already exists. Please verify the email address',
         fr:
            "L'utilisateur existe déjà. S'il vous plaît vérifier l'adresse e-mail",
      },
      missingLabel: {
         es: 'Falta la etiqueta en translation.js',
         fr: 'Label manquant dans translation.js',
         en: 'Missing label in translation.js',
      },
   },
   screens: {
      home: 'TOTALYMAGE',
      signin: { es: 'Iniciar sesión', fr: 'Connexion', en: 'Log In' },
      'pw-forget': {
         es: 'Restablecer contraseña',
         fr: 'Réinitialisation de mot de passe',
         en: 'Password Reset',
      },
      onboarding: {
         es: 'Activación de la cuenta de usuario',
         fr: 'Activation de votre compte utilisateur',
         en: 'User Account Activation',
         pending_invites: {
            es: 'Tienes invitaciones de grupo pendientes',
            fr: `Vous avez des invitations de groupe en attente`,
            en: `You have pending group invitations`,
         },
         accept_header: {
            es: 'Aceptar invitación',
            fr: `Accepter l'invitation`,
            en: `Accept Invitation`,
         },
         accept_message: {
            es:
               'Invitado por %0, está a punto de unirse al grupo %1 de la empresa %2',
            fr: `A l'invitation de %0, vous vous apprêtez à rejoindre le groupe '%1' de la société %2`,
            en: `Invited by %0, you are about to join the group %1 of company %2`,
         },
      },
      companies: { es: 'Empresas', fr: 'Sociétés', en: 'Companies' },
      mycompany: { es: 'Empresas', fr: 'Ma société', en: 'My Company' },
      detail: { es: 'Detalles', fr: 'Détails', en: 'Details' },
      users: { es: 'Usuarios', fr: 'Utilisateurs', en: 'Users' },
      groups: { es: 'Grupos', fr: 'Groupes', en: 'Groups' },
      guests: { es: 'Invitados', fr: 'Invités', en: 'Guests' },
      guests_invite: {
         es: 'Enviar invitación para unirse al grupo',
         en: 'Send invitation to join group',
         fr: 'Inviter à joindre le groupe',
      },
      guests_invite_detail: {
         es: 'El invitado será miembro del grupo "%0"',
         en: 'Guest will be member of group "%0"',
         fr: `L'invité sera membre du groupe "%0"`,
      },
      sessions: {
         es: 'Sesiones',
         fr: 'Sessions',
         en: 'Sessions',
      },
      environments: {
         es: 'Entornos',
         fr: 'Environnements',
         en: 'Environments',
      },
      video360: { es: 'Videos 360°', fr: 'Vidéos 360°', en: '360° Videos' },
      video3d: { es: 'Videos 3D', fr: 'Vidéos 3D', en: '3D Videos' },
      medias: { es: 'Medios', fr: 'Médias', en: 'Medias' },
      completedMedias: {
         fr: 'Médias mode liste',
         en: 'In Sync Medias',
         es: 'Medios sincronizados',
      },
      completedMediasGrid: {
         fr: 'Médias mode grille',
         en: 'Medias Grid',
         es: 'Medios sincronizados',
      },
      pendingMedias: {
         fr: 'Médias en attente',
         en: 'Pending Medias',
         es: 'Medios pendientes',
      },
      medias_shares: {
         es: `Versiones de entorno vinculados`,
         fr: `Versions d'environnement liées`,
         en: `Linked Environment Versions`,
      },
      medias_create: {
         es: 'Nueva media',
         fr: 'Nouveau média',
         en: 'New Media',
      },
      completedEnvironment: {
         fr: 'Environnements terminées',
         en: 'Completed Environments',
         es: 'Entornos completados',
      },
      pendingEnvironment: {
         fr: 'Environnements en attente',
         en: 'Pending Environments',
         es: 'Ambientes pendientes',
      },
      completedScenes: {
         es: 'Vídeos 3D completados',
         fr: 'Vidéos 3D terminées',
         en: 'Completed 3D Videos',
      },
      progressScenes: {
         es: 'Vídeos 3D en curso',
         fr: 'Vidéos 3D en cours',
         en: '3D Videos in progress',
      },
      failedScenes: {
         es: 'Vídeos 3D fallidos',
         fr: 'Vidéos 3D échouées',
         en: 'Failed 3D Videos',
      },
      completedVideos: {
         es: 'Vídeos completados',
         fr: 'Vidéos terminées',
         en: 'Completed videos',
      },
      pendingVideos: {
         es: 'Videos pendientes',
         fr: 'Vidéos en attente',
         en: 'pending videos',
      },
      relatedEvents: {
         fr: 'Evénements liés',
         en: 'Related events',
         es: 'Eventos relacionados',
      },
      downloads: { es: 'Descargables', fr: 'Téléchargeables', en: 'Downloads' },
      templates: { es: 'Plantillas', fr: 'Modèles', en: 'Templates' },
      administration: {
         es: 'Administración',
         fr: 'Administration',
         en: 'Administration',
      },
      logout: { es: 'Desconexión', fr: 'Déconnexion', en: 'Logout' },
      home_welcome: {
         es: 'Bienvenido a TotalYmage',
         fr: 'Bienvenue chez TotalYmage',
         en: 'Welcome to TotalYmage',
      },
      company: {
         es: 'Gestión de empresas',
         fr: 'Gestion des sociétés',
         en: 'Companies Management',
      },
      company_title: {
         es: 'Empresas',
         fr: 'sociétés',
         en: 'Companies',
      },
      company_create: {
         es: 'Nueva Empresa',
         fr: 'Nouvelle société',
         en: 'New Company',
      },
      company_modify: {
         es: 'Modificación de la empresa',
         fr: 'Modification de société',
         en: 'Company modification',
      },
      company_show: {
         es: 'Empresa %0',
         fr: 'Société %0',
         en: 'Company %0',
      },
      company_credits: {
         es: 'Añadir créditos',
         fr: 'Ajouter des crédits',
         en: 'Add credits',
      },
      company_groups: {
         es: 'Gestión de grupos',
         fr: 'Gestion des groupes',
         en: 'Groups Management',
      },
      company_group_create: {
         es: 'Novo grupo',
         fr: 'Nouveau groupe',
         en: 'New Group',
      },
      company_purchases: {
         es: 'Gestión de suscripción',
         fr: 'Gestion des souscriptions',
         en: 'Purchases Management',
      },
      company_purchase_create: {
         es: 'Novo suscripción',
         fr: 'Nouvelle souscription',
         en: 'New Purchase',
      },
      user: {
         es: 'Gestión de usuarios',
         fr: 'Gestion des utilisateurs',
         en: 'Users Management',
      },
      user_record: {
         es: 'Configuración del usuario',
         fr: 'Paramètres utilisateur',
         en: 'User Settings',
      },
      user_create: {
         es: 'Nuevo usuario',
         fr: 'Nouvel utilisateur',
         en: 'New User',
      },
      user_show: {
         es: 'Detalles de usuario',
         fr: "Détails d'un utilisateur",
         en: 'User Details',
      },
      user_invite: {
         es: 'Invitar a usuario',
         fr: 'Inviter un utilisateur',
         en: 'Invite User',
      },
      user_switch_active: {
         es: 'Usuarios',
         fr: 'Utilisateurs',
         en: 'Users',
      },
      user_switch_invite: {
         es: 'Invitaciones pendientes',
         fr: 'Invitations en attente',
         en: 'Pending invitations',
      },
      user_switch_deleted: {
         es: 'Usuarios eliminados',
         fr: 'Utilisateurs supprimés',
         en: 'Deleted users',
      },
      user_clear_local_storage: {
         es: 'Borrar almacenamiento local',
         fr: 'Vider le cache local',
         en: 'Clear local storage',
      },
      user_clear_session_storage: {
         es: 'Borrar almacenamiento sesión',
         fr: 'Vider le cache de session',
         en: 'Clear session storage',
      },
      user_keywords_refresh: {
         es: 'Actualizar palabras clave',
         fr: 'Actualiser les mots-clés',
         en: 'Keywords refresh',
      },
      user_reset_counter: {
         es: 'Actualizar los contadores',
         fr: 'Actualiser les compteurs',
         en: 'Counter refresh',
      },
      user_keywords_selection_collection: {
         es: 'elegir una colección',
         fr: 'choisir une collection',
         en: 'choose a collection',
      },
      user_modify: {
         es: 'Modificación usuario',
         fr: 'Modification utilisateur',
         en: 'User modification',
      },
      sessions_show: {
         es: 'Detalle de sesión',
         fr: "Détail d'une session",
         en: 'Session Detail',
      },
      environment: {
         es: 'Gestión de entornos',
         fr: 'Gestion des environnements',
         en: 'Environments Management',
      },
      environment_show: {
         details: {
            es: 'Detalles del entorno',
            fr: `Détails de l'environnement`,
            en: 'Environment details',
         },
         versions: {
            es: 'Versiones disponibles',
            fr: 'Versions disponibles',
            en: 'Available versions',
         },
         usedVectos: {
            es: 'Vídeos 3D integrados',
            fr: 'Vidéos 3D intégrées',
            en: 'Embedded 3D Videos',
         },
         usedMedias: {
            es: 'Medias integrados',
            fr: 'Médias intégrées',
            en: 'Embedded Medias',
         },
         versionTree: {
            es: 'Estructura de datos',
            fr: 'Arborescence de données',
            en: 'Data Tree',
         },
      },
      video: {
         es: 'Gestión de vídeo de 360°',
         fr: 'Gestion des vidéos 360°',
         en: '360° Videos Management',
      },
      video360_create: {
         es: 'Nuevo vídeo 360°',
         fr: 'Nouvelle vidéo 360°',
         en: 'New 360° Video',
      },
      video360_job: {
         es: 'Conversión insta360',
         fr: 'Conversion insta360°',
         en: 'Insta360 Conversion',
      },
      video360_show: {
         details: {
            es: 'Detalles de video 360°',
            fr: 'Détails de la vidéo 360°',
            en: 'Details of 360° video',
         },
         watch: {
            es: 'Ver vídeo 360°',
            fr: 'Voir la vidéo 360°',
            en: 'Watch 360° Video',
         },
         scenes: {
            es: 'Vídeos 3D generados',
            fr: 'Vidéos 3D générées',
            en: 'Generated 3D Videos',
         },
      },
      video360_modify: {
         es: 'Modificación del vídeo 360°',
         fr: 'Modification de la vidéo 360°',
         en: 'Modify the 360° Video',
      },
      video360_vecto: {
         es: `Vectorización del vídeo de 360° '%0'`,
         fr: `Vectorisation de la vidéo 360° '%0'`,
         en: `Vectorization of the 360° Video '%0'`,
      },
      video3d_rename: {
         es: `Renombrando vídeo 3D '%0'`,
         fr: `Renommage de la vidéo 3D '%0'`,
         en: `Renaming of the 3D Video '%0'`,
      },
      video3d_page_rename: {
         es: `Renombrar`,
         fr: `Renommer`,
         en: `Rename`,
      },
      video3d_show: {
         es: 'Detalles del vídeo 3D',
         fr: 'Détails de la vidéo 3D',
         en: 'Details of the 3D video',
      },
      video3d_events: {
         es: 'Eventos de vectorización',
         fr: `Evénements de vectorisation`,
         en: `Vectorization Events`,
      },
      video3d_shares: {
         es: `Versiones de entorno vinculados`,
         fr: `Versions d'environnement liées`,
         en: `Linked Environment Versions`,
      },
      downloads_create: {
         es: 'Nueva descarga',
         fr: 'Nouveau téléchargeable',
         en: 'New Download',
      },
      downloads_modify: {
         es: 'Descargar actualización',
         fr: `Modification d'un téléchargeable`,
         en: 'Download Update',
      },
      downloads_list: {
         es: 'Descargas',
         fr: 'Téléchargements',
         en: 'Downloads',
      },
      downloads_show: {
         es: `Detalles del archivo '%0'`,
         fr: `Détails du fichier '%0'`,
         en: 'Details of %0 file',
      },
      downloads_page_show: {
         es: 'Detalles del archivo',
         fr: `Détails du fichier`,
         en: 'File Details',
      },
      templates_create: {
         es: 'Nueva plantilla',
         fr: 'Nouveau modèle',
         en: 'New Template',
      },
      templates_modify: {
         es: 'Modificación de plantilla',
         fr: `Modification d'un modèle`,
         en: 'Template Modification',
      },
   },
   screens_info: {
      home_welcome: {
         es:
            'Encontrará a continuación los enlaces de descarga a las últimas versiones de nuestra aplicación y nuestras condiciones generales',
         fr:
            "Vous trouverez ci-dessous les liens de téléchargements des dernières versions de notre application et de nos conditions générales d'utilisation",
         en:
            'You will find below the download links to the latest releases of our application and our general conditions',
      },
      onboarding: {
         es:
            'Para activar tu cuenta, por favor introduce tu nombre y apellidos y una contraseña y luego haz clic en el botón azul',
         fr:
            "Pour terminer l'activation de votre compte, veuillez saisir votre nom et prénom ainsi qu'un mot de passe puis cliquez sur le bouton.",
         en:
            'To activate your account, please enter your first and last names and a password then click on the blue button',
      },
      video360_vecto: {
         es: `Has subido con éxito tu vídeo 360°. 
        Para crear un vídeo 3D de la ubicación capturada, pulse el botón "vecto". Puede empezar a seleccionar la primera imagen y la última imagen de la ubicación en las imágenes del vídeo 360°. Debe introducir un nombre para su vídeo 3D y una velocidad de fotogramas para establecer la distancia correcta entre las imágenes; por defecto puede pegarse a la velocidad de fotogramas de grabación real de su vídeo 360° (normalmente 29 fps para cámaras de vídeo estándar de 4K 360°) o puede reducirlo. Al guardar estos parámetros, el vídeo 360° es analizado por los algoritmos propietarios de TotalYmage en un servidor dedicado y el directorio que contiene los datos de vídeo 3D se crea en su biblioteca « Vídeos 3D » al final de los cálculos.
        En todo momento podrás gestionar todos tus vídeos 3D en el backoffice y descargarlos en directorios específicos de "Vídeos 3D" vinculados a tus entornos interactivos.
        Al utilizar la aplicación TotalYmage, puede crear una escena seleccionando un vídeo 3D en la lista desplegable. Estas escenas son compatibles con las superposiciones posicionadas 2D y 3D que puede crear. Los usuarios que accedan a los entornos TotalYmage podrán navegar en las escenas e interactuar con las superposiciones.`,
         en: `You have successfully uploaded your 360° video.
        To create a 3D video of the captured location, please push the “vecto” button. You can start selecting the first image and the last image of the location from the images of the 360° video. You must enter a name for your 3D video and a framerate to set the right distance between the images ; by default you can stick to the actual recording framerate of your 360° video (usually 29 fps for standard 4K 360° video cameras) or you can reduce it. When saving these parameters, your 360° video is analyzed by the TotalYmage proprietary algorithms on a dedicated server and the directory containing the 3D video data is created in your « 3D Videos » library at the end of the calculations.
        At all times you are able to manage all your 3D videos in the backoffice, and to download them in specific “3D Videos” directories linked to your interactive environments.
        When using the TotalYmage application you can create a scene by selecting a 3D video from the drop down list. These scenes are the support to the 2D and 3D positioned overlays you are able to create. Users accessing the TotalYmage environments will be able to navigate in the scenes and interact with the overlays.`,
         fr: `Vous avez brillamment chargé votre vidéo 360°.
        Pour créer une vidéo 3D du lieu filmé, appuyez sur le bouton «vecto». Vous pouvez sélectionner la première et la dernière image du lieu à partir des images de la vidéo 360°. Vous devez saisir le nom de votre vidéo 3D et une fréquence pour ajuster la distance entre les images ; par défaut vous pouvez garder la fréquence de captation de votre vidéo 360° (en général à 29 images par seconde pour une caméra vidéo 360° standard) ou entrer une plus petite valeur. Quand vous sauvez ces paramètres, votre vidéo 360° est analysée par les algorithmes propriétaires TotalYmage sur un serveur dédié et le répertoire correspondant aux données de la vidéo 3D est créé dans votre bibliothèque « Vidéos 3D » à la fin des calculs.
        A tout moment vous pouvez gérer toutes vos vidéos 3D dans le backoffice, et les télécharger dans les répertoires « 3D Videos » liés à vos environnements interactifs.
        Dans l’application TotalYmage, vous pouvez créer une scène en sélectionnant une vidéo 3D dans le menu déroulant. Ces scènes sont le décor des surcouches positionnées en 2D et 3D que vous pouvez alors créer. Les utilisateurs accédant aux environnements TotalYmage pourront alors se déplacer dans les scènes et interagir avec les surcouches.`,
      },
   },
   form: {
      submit: { es: 'Guardar', fr: 'Enregistrer', en: 'Save' },
      cancel: { es: 'Cancelar', fr: 'Annuler', en: 'Cancel' },
      confirm: { es: 'Confirmar', fr: 'Confirmer', en: 'Confirm' },
      back: { es: 'Retroceder', fr: 'Retour', en: 'Back' },
      delete: { es: 'Suprimir', fr: 'Supprimer', en: 'Delete' },
      saving: {
         es: 'Guardando en curso',
         fr: 'Sauvegarde en cours...',
         en: 'Saving in progress...',
      },
      loading: {
         es: 'Carga en curso...',
         fr: 'Chargement en cours...',
         en: 'Loading in progress...',
      },
      download: { es: 'Descargar', fr: 'Télécharger', en: 'Download' },
      password: {
         confirm: {
            es: 'Confirmar',
            fr: 'Confirmation',
            en: 'Confirm',
         },
         forget: {
            es: '¿Olvidó la contraseña?',
            fr: 'Mot de passe oublié ?',
            en: 'Forgot Password?',
         },
         reset: {
            es: 'Envío de correo electrónico en curso...',
            fr: "Envoi d'email en cours...",
            en: 'Email sending in progress...',
         },
      },
      file: {
         upload: {
            es:
               'Arrastre y suelte un archivo o haga clic para abrir un cuadro de selección',
            fr:
               'Glissez et déposez un fichier ou cliquez pour ouvrir une boite de sélection',
            en: 'Drag & drop a file or click to open a selection box',
         },
         current: {
            es: 'actualmente',
            fr: 'actuellement',
            en: 'currently',
         },
      },
      warnings: {
         required_param_header: {
            es: `Se requiere parámetro de empresa`,
            fr: `Paramètre 'Société' requis`,
            en: `Company Parameter is required`,
         },
         required_param_content: {
            es: `Seleccione una empresa para acceder a la lista correspondiente`,
            fr: `Sélectionnez une société pour accéder à la liste correspondante`,
            en: `Select a company to access the relevant list`,
         },
      },
      errors: {
         missing_values: {
            es: 'Por favor, corrija los errores en los siguientes campos:',
            fr: 'Veuillez corriger les erreurs dans les champs suivants :',
            en: 'Please correct the errors in the following fields:',
         },
         missing_action: {
            es: 'Ninguna acción de copia de seguridad definida',
            fr: 'Aucune action de sauvegarde définie',
            en: 'No saving action has been defined',
         },
         server_error: {
            es: 'Error del servidor:',
            fr: 'Erreur serveur : ',
            en: 'Server Error: ',
         },
      },
      select: {
         es: 'Seleccionar',
         fr: 'Sélectionnez',
         en: 'Select',
      },
      agreement: {
         es:
            'Por la presente confirmo que leo y acepto plenamente los términos generales de TotalYmage',
         fr:
            "Je reconnais avoir lu et accepté les conditions générales d'utilisation TotalYmage",
         en:
            'I hereby confirm that I read and fully accept TotalYmage general terms',
      },
   },
   fields: {
      name: {
         es: 'Nombre',
         fr: 'Nom',
         en: 'Name',
      },
      new_name: {
         es: 'Nuevo nombre',
         fr: 'Nouveau Nom',
         en: 'New Name',
      },
      name_creator: {
         es: 'Nombre, creador',
         fr: 'Nom, Créateur',
         en: 'Name, creator',
      },
      lastname_firstname_email: {
         es: 'Apellido, Nombre o Correo electrónico',
         fr: 'Nom, Prénom ou Email',
         en: 'Lastname, Firstname or Email',
      },
      lastname_firstname_email_city: {
         es: 'Apellido, Nombre, Correo electrónico o ciudad',
         fr: 'Nom, Prénom, Email ou ville',
         en: 'Lastname, Firstname, Email or City',
      },
      password_reset: {
         es: 'Restablecer contraseña',
         en: 'Reset password',
         fr: 'Réinitialiser le mot de passe',
      },
      activate_account: {
         es: 'Activar mi cuenta',
         fr: 'Activer mon compte',
         en: 'Activate my account',
      },
      domain_name: {
         es: 'Nombre dominio',
         fr: 'Nom de domaine',
         en: 'Domain name',
      },
      firstname: {
         es: 'Nombre',
         fr: 'Prénom',
         en: 'First Name',
      },
      lastname: {
         es: 'Apellido',
         fr: 'Nom',
         en: 'Last Name',
      },
      email: {
         es: 'Dirección correo electrónico',
         fr: 'Adresse email',
         en: 'Email Address',
      },
      email_login: {
         es: 'Dirección de correo electrónico (inicio de sesión)',
         fr: 'Adresse email (identifiant)',
         en: 'Email Address (login)',
      },
      resend_mail: {
         es: 'Reenviar correo electrónico',
         en: 'Re-send mail',
         fr: "ré-envoyer l'email",
      },
      file: {
         es: 'Archivo',
         fr: 'Fichier',
         en: 'File',
      },
      files: {
         es: 'Archivos',
         fr: 'Fichiers',
         en: 'Files',
      },
      filename: {
         es: 'Nombre de Archivo',
         fr: 'Nom du fichier',
         en: 'File Name',
      },
      delete_file_after: {
         fr: 'Supprimer les fichiers %0 après succès',
         en: 'Delete %0 files after success',
         es: 'Eliminar archivos %0 después del éxito',
      },
      guest: {
         es: 'Invitado',
         fr: 'Invité',
         en: 'Guest',
      },
      guests: {
         es: 'Invitados',
         fr: 'Invités',
         en: 'Guests',
      },
      group: {
         es: 'Grupo',
         fr: 'Groupe',
         en: 'Group',
      },
      group_delete: {
         es: 'Eliminar grupo',
         fr: 'Supprimer le groupe',
         en: 'Delete Group',
      },
      groups: { es: 'Grupos', fr: 'Groupes', en: 'Groups' },
      role: {
         es: 'Rol',
         en: 'Role',
         fr: 'Rôle',
      },
      length: {
         es: 'Duración',
         fr: 'Durée',
         en: 'Length',
      },
      Worker: {
         es: 'Trabajador',
         fr: 'Agent',
         en: 'Worker',
      },
      agent: {
         es: 'Agente',
         fr: 'Agent',
         en: 'Agent',
      },
      client: {
         es: 'Cliente',
         fr: 'Client',
         en: 'Client',
      },
      operating_system: {
         es: 'Sistema Operativo',
         fr: `Système d'exploitation`,
         en: 'Operating System',
      },
      unity_version: {
         es: 'Versión Unity',
         fr: 'Version Unity',
         en: 'Unity Version',
      },
      totalymage_version: {
         es: 'Versión TotalYmage',
         fr: 'Version TotalYmage',
         en: 'TotalYmage Version',
      },
      device: {
         es: 'Dispositivo',
         fr: 'Appareil',
         en: 'Device',
      },
      ram: {
         es: 'RAM',
         fr: `RAM`,
         en: 'RAM',
      },
      os: {
         es: 'S.O.',
         fr: 'OS',
         en: 'OS',
      },
      location: {
         es: 'Localidad',
         fr: 'Localisation',
         en: 'Location',
      },
      city: {
         es: 'Ciudad',
         fr: 'Ville',
         en: 'City',
      },
      country: {
         es: 'País',
         fr: `Pays`,
         en: 'Country',
      },
      disabled_access: {
         es: 'Acceso disponible',
         fr: 'Accès activé',
         en: 'Enabled Access',
      },
      notifications_recipient: {
         es: 'Destinatario de notificaciones',
         fr: 'Reçoit les notifications',
         en: 'Notifications recipient',
      },
      updated_at: {
         es: 'Última actualización',
         fr: 'Dernière mise à jour',
         en: 'Last Update',
      },
      video_3d: {
         es: 'Vídeo 3D',
         fr: 'Vidéo 3D',
         en: '3D Video',
      },
      video: {
         es: 'Vídeo',
         fr: 'Vidéo',
         en: 'Video',
      },
      framerate: {
         es: 'Frecuencia Fotogramas',
         fr: 'Framerate',
         en: 'Framerate',
      },
      vecto_framerate: {
         es: 'Fotograma vecto',
         fr: 'Framerate vecto',
         en: 'Vecto Framerate',
      },
      length_convert: {
         es: 'Tiempo para convertir',
         fr: 'Durée à convertir',
         en: 'Length to convert',
      },
      start_offset: {
         es: 'Desplazamiento del inicio',
         fr: 'Offset Début',
         en: 'Start Offset',
      },
      end_offset: {
         es: 'Desplazamiento del fin',
         fr: 'Offset Fin',
         en: 'End Offset',
      },
      contentType: {
         fr: 'Content Type',
         en: 'Content Type',
         es: 'Content Type',
      },
      checksum: {
         fr: 'Checksum',
         en: 'Checksum',
         es: 'Checksum',
      },
      start: { es: 'Inicio', fr: 'Début', en: 'Start' },
      end: { es: 'Fin', fr: 'Fin', en: 'End' },
      user_seats: {
         es: 'Asientos de usuario',
         fr: 'Sièges utilisateur',
         en: 'User seats',
      },
      users_total: {
         es: 'Usuarios',
         fr: 'Utilisateurs',
         en: 'Users',
      },
      users_active: {
         es: 'En activo',
         fr: 'Dont actifs',
         en: 'Actives',
      },
      minutes_total: {
         es: 'Minutos',
         fr: 'Minutes',
         en: 'Minutes',
      },
      minutes_used: {
         es: 'En usar',
         fr: 'Consommées',
         en: 'Consumed',
      },
      user_invite: {
         es: 'Invitar a usuario',
         fr: "Inviter l'utilisateur",
         en: 'Invite User',
      },
      user_delete: {
         es: 'Eliminar usuario',
         en: 'Delete user',
         fr: "Supprimer l'utilisateur",
      },
      invitation_date: {
         es: 'Fecha de invitación',
         en: 'Invitation date',
         fr: "Date d'invitation",
      },
      offline_data_requested: {
         es: 'Datos fuera de línea solicitados',
         fr: 'Données Offline demandées',
         en: 'Offline data requested',
      },
      offline_data_transmitted: {
         es: 'Datos sin conexión transmitidos',
         fr: 'Données Offline transmises',
         en: 'Offline data transmitted',
      },
      visitor_seats: {
         es: 'Asientos de visitante',
         fr: 'Sièges visiteur',
         en: 'Visitor seats',
      },
      video_quota: {
         es: 'Min Vídeos 3D',
         fr: 'Min Vidéos 3D',
         en: '3D Videos Min',
      },
      status: {
         es: 'Estado',
         fr: 'Statut',
         en: 'Status',
      },
      new_status: {
         es: 'Nuevo estado',
         fr: 'Nouveau Statut',
         en: 'New status',
      },
      data_access: {
         es: 'Acceso a datos',
         fr: 'Accès données',
         en: 'Data access',
      },
      invited_by: {
         es: 'Invitado por',
         fr: 'Invité par',
         en: 'Invited by',
      },
      offline_file_lifetime: {
         es: 'Período de validez del archivo sin conexión',
         fr: 'Durée de validité du fichier offline',
         en: 'Offline File Lifetime',
      },
      language: { es: 'Idioma', fr: 'Langue', en: 'Language' },
      accessible: {
         es: 'Es accesible',
         fr: 'Est accessible',
         en: 'Is accessible',
      },
      access_list: {
         es: 'Lista de acceso',
         fr: "Liste d'accès",
         en: 'Access List',
      },
      log: {
         es: 'Registro de procesamiento',
         fr: 'Journal de traitement',
         en: 'Processing log',
      },
      computed_access_list: {
         es: 'Lista de acceso calculada',
         fr: "Liste d'accès calculée",
         en: 'Computed Access List',
      },
      default_access_list: {
         es: 'Lista de acceso predeterminada',
         fr: "Liste d'accès par défaut",
         en: 'Default Access List',
      },
      downloads: {
         es: 'Descarga',
         fr: 'Télécharger',
         en: 'Download',
      },
      company: {
         es: 'Empresa',
         fr: 'Société',
         en: 'Company',
      },
      environment: {
         es: 'Entorno',
         fr: 'Environnement',
         en: 'Environment',
      },
      latest_download: {
         es: 'Última descarga',
         fr: 'Dernier téléchargement',
         en: 'Latest download',
      },
      created_at: {
         es: 'Creado',
         fr: 'Créé',
         en: 'Created',
      },
      latest_version_at: {
         es: 'Última Versión',
         fr: 'Dernière version',
         en: 'Latest version',
      },
      creation: {
         es: 'Creación',
         fr: 'Création',
         en: 'Creation',
      },
      creator: {
         es: 'Creado',
         fr: 'Créateur',
         en: 'Creator',
      },
      by: {
         es: 'Por',
         fr: 'Par',
         en: 'By',
      },
      company_partner: {
         es: 'Empresa asociada',
         fr: 'Société partenaire',
         en: 'Partner Company',
      },
      can_create_company: {
         es: 'Puede crear empresas',
         fr: 'Peut créer des entreprises',
         en: 'Can create companies',
      },
      created: {
         es: 'Fecha de creación',
         en: 'Created',
         fr: 'Date de Création',
      },
      debut: {
         es: 'Comienzo',
         fr: 'Début',
         en: 'Debut',
      },
      duration: {
         es: 'Duración',
         fr: 'Durée',
         en: 'Duration',
      },
      login: {
         es: 'Inicio sesión',
         fr: 'Identifiant',
         en: 'Login',
      },
      log_in: { es: 'Conexión', fr: 'Connexion', en: 'Log in' },
      microsoft_login: {
         es: 'Autenticación de Microsoft',
         en: 'Microsoft Login',
         fr: 'Authentification Microsoft',
      },
      email_model: {
         es: 'Plantilla de correo electrónico',
         fr: `Modèle d'email`,
         en: `Email template`,
      },
      action: {
         es: 'Acción',
         fr: 'Action',
         en: 'Action',
      },
      subject: {
         es: 'Asunto',
         fr: 'Sujet',
         en: 'Subject',
      },
      fr: {
         es: 'Versión en francés',
         fr: 'Version française',
         en: 'French Version',
      },
      en: {
         es: 'Versión en inglés',
         fr: 'Version anglaise',
         en: 'English Version',
      },
      es: {
         es: 'Versión en español',
         fr: 'version espagnole',
         en: 'Spanish Version',
      },
      content: {
         es: 'Contenido',
         fr: 'Contenu',
         en: 'Content',
      },
      last_login: {
         es: 'Última conexión',
         en: 'Last Login',
         fr: 'Dernière connexion',
      },
      password: {
         es: 'Contraseña',
         fr: 'Mot de passe',
         en: 'Password',
      },
      user: {
         es: 'Usuario',
         fr: 'Utilisateur',
         en: 'User',
      },
      partner: {
         es: 'Socio',
         fr: 'Partenaire',
         en: 'Partner',
      },
      partner_information: {
         es: 'Información del socio',
         fr: 'Informations partenaire',
         en: 'Partner information',
      },
      allow_partner_access: {
         es: 'Socio como gestor de datos',
         fr: 'Partenaire gestionnaire de données',
         en: 'Partner as data Manager',
      },
      third_place_authentication: {
         es: 'Autenticación de terceros',
         fr: 'Tierce authentification',
         en: 'Third-party authentication',
      },
      delay_days_complete_execution: {
         es: 'Retraso en los días hasta la ejecución completa a las 0:00 CET',
         fr: 'Délai en jours avant exécution complète à 0:00 CET',
         en: 'Delay in days until complete execution at 0:00 CET',
      },
      latest_updated: {
         es: 'Última actualización el',
         fr: 'Dernière mise à jour',
         en: 'Latest Updated At',
      },
      created_by: {
         es: 'Creado por',
         fr: 'Créé par',
         en: 'Created by',
      },
      credit_type: {
         es: 'Tipo de crédito',
         fr: 'Type de crédit',
         en: 'Credit type',
      },
      offline_access: {
         es: 'Acceso al modo sin conexión',
         fr: 'Accès au mode offline',
         en: 'Offline Mode Access',
      },
      activate_offline_mode: {
         es: 'Activación del modo sin conexión',
         fr: 'Activation Mode Offline',
         en: 'Offline Mode Activation',
      },
      vectorization_mode: {
         es: 'Modo vectorización',
         fr: 'Mode de vectorisation',
         en: 'Vectorization Mode',
      },
      fast_mode: { es: 'Modo Rápido', fr: 'Mode Rapide', en: 'Fast Mode' },
      accurate_mode_video: {
         es: 'Modo preciso (vídeo)',
         fr: 'Mode Précis (Vidéo)',
         en: 'Accurate Mode (Video)',
      },
      accurate_mode_image: {
         es: 'Modo preciso (imagen)',
         fr: 'Mode Précis (Image)',
         en: 'Accurate Mode (Image)',
      },
      quantity_add_remove: {
         es: 'Cantidad para añadir o eliminar',
         fr: 'Quantité à ajouter ou retrancher',
         en: 'Quantity to add or remove',
      },
      quantity: {
         es: 'Cantidad',
         fr: 'Quantité',
         en: 'Quantity',
      },
      versions: {
         es: 'Versiones',
         fr: 'Versions',
         en: 'Versions',
      },
      size: {
         es: 'Tamaño',
         fr: 'Taille',
         en: 'Size',
      },
      host: {
         es: 'Host',
         fr: 'Hôte',
         en: 'Host',
      },
      resolution: {
         es: 'Resolución',
         fr: 'Résolution',
         en: 'Resolution',
      },
      bitrate: {
         es: 'Tasa de bits',
         fr: 'Débit binaire',
         en: 'Bitrate',
      },
      vecto: {
         es: 'Vecto',
         en: 'Vecto',
         fr: 'Vecto',
      },
      version: {
         es: 'Versión',
         fr: 'Version',
         en: 'Version',
      },
      revision: {
         es: 'Revisión',
         fr: 'Révision',
         en: 'Revision',
      },
      default_role: {
         es: 'Rol por defecto',
         fr: 'Rôle par défaut',
         en: 'Default Role',
      },
      specialUsage: {
         es: 'Uso',
         fr: 'Usage',
         en: 'Usage',
      },
      specialUsageValues: {
         generic: {
            es: 'Genérico',
            fr: 'Générique',
            en: 'Generic',
         },
         icon: {
            es: 'Icono',
            fr: 'Icône/Logo',
            en: 'Icon/Logo',
         },
         overlayIcon: {
            es: 'Icono de superposición',
            fr: 'Icône de surcouche',
            en: 'Overlay Icon',
         },
         preview: {
            es: 'Avance',
            fr: 'Prévisualisation',
            en: 'Preview',
         },
         templateMedia: {
            es: 'Medios de plantilla',
            fr: 'Média par défaut',
            en: 'Template Media',
         },
      },
      type: {
         es: 'Tipo',
         fr: 'Type',
         en: 'Type',
      },
      comment: {
         es: 'Comentario',
         fr: 'Commentaire',
         en: 'Comment',
      },
      course: { fr: 'Parcours', en: 'Course', es: 'Curso' },
      course_linear: {
         fr: 'Linéaire',
         en: 'Linear',
         es: 'Lineal',
      },
      course_nonlinear: {
         fr: 'Non linéaire',
         en: 'Non Linear',
         es: 'No lineal',
      },
      generate_version: {
         fr: 'Générer une version %0',
         en: 'Generate a %0 Version',
         es: 'Genera una versión %0',
      },
      date: {
         es: 'Fecha',
         fr: 'Date',
         en: 'Date',
      },
      members: {
         es: 'Miembros',
         fr: 'Membres',
         en: 'Members',
      },
      search: {
         es: 'Buscar',
         fr: 'Rechercher',
         en: 'Search',
      },
      reset: {
         es: 'Reiniciar',
         fr: 'Vider',
         en: 'Reset',
      },
      share: {
         es: 'Compartir',
         en: 'Share',
         fr: 'Partage',
      },
      shares: {
         es: 'Acciones',
         en: 'Shares',
         fr: 'Partages',
      },
      shared_by: {
         es: 'Compartido por',
         fr: 'Partagé par',
         en: 'Shared by',
      },
      show: {
         es: 'Mostrar',
         en: 'Show',
         fr: 'Voir',
      },
      modify: {
         es: 'Modificar',
         en: 'Modify',
         fr: 'Modifier',
      },
      accept: {
         es: 'Aceptar',
         en: 'Accepter',
         fr: 'Accept',
      },
      delete: {
         es: 'Eliminar',
         en: 'Delete',
         fr: 'Supprimer',
      },
      regenerate_thumbnail: {
         es: 'Regenerar miniatura',
         en: 'Regenerate thumbnail',
         fr: 'Regénérer la vignette',
      },
      deleted_at: {
         es: 'Eliminado en',
         fr: 'Supprimé le',
         en: 'Deleted at',
      },
      deleted_by: {
         es: 'Eliminado por',
         fr: 'Supprimé par',
         en: 'Deleted by',
      },
      restore: {
         es: 'Restaurar',
         en: 'Restore',
         fr: 'Restaurer',
      },
      purchases: {
         es: 'Suscripción',
         en: 'Purchases',
         fr: 'Souscriptions',
      },
      event: { es: 'Eventos', fr: 'Événements', en: 'Events' },
   },
   clear_local_storage: {
      header: {
         es: 'Esta acción te desconectará',
         fr: 'Cette action va vous déconnecter',
         en: 'This action will disconnect you',
      },
      content: {
         es: '¿Estás seguro?',
         fr: ' Êtes-vous sûr ?',
         en: 'Are you sure?',
      },
   },
   clear_session_storage: {
      header: {
         es: 'Esta acción limpiará el almacenamiento de sesiones',
         fr: 'Cette action va vider le cache de session',
         en: 'This action will clear session storage',
      },
      content: {
         es: '¿Estás seguro?',
         fr: ' Êtes-vous sûr ?',
         en: 'Are you sure?',
      },
   },
   keywords_refresh: {
      header: {
         es: 'Esta acción actualizará las palabras clave',
         fr: 'Cette action va actualiser les mots-clés',
         en: 'This action will refresh keywords',
      },
   },
   reset_counter: {
      header: {
         es: 'Esta acción refresca el contador',
         fr: 'Cette action va actualiser les compteurs',
         en: 'This action will refresh counter',
      },
   },
   delete: {
      header: {
         es: 'Usted está a punto de eliminar este registro permanentemente',
         fr: "Vous allez supprimer définitivement l'enregistrement",
         en: 'You are about to delete this record permanently',
      },
      content: {
         es: '¿Estás seguro?',
         fr: 'Etes-vous sûr ?',
         en: 'Are you sure?',
      },
      cancel: {
         es: 'Cancelar eliminación',
         fr: 'Annuler la suppression',
         en: 'Cancel deletion',
      },
      confirm: {
         es: 'Confirmar eliminación',
         fr: 'Confirmer la suppression',
         en: 'Confirm deletion',
      },
   },
   upload: {
      header: {
         es: 'Transmitiendo al servidor',
         fr: 'Envoi au serveur',
         en: 'Transmitting to server',
      },
      subheader: {
         es: 'Por favor, espere mientras el archivo se está cargando',
         fr: 'Veuillez patienter pendant le téléchargement du fichier',
         en: 'Please wait while file is uploading',
      },
      errors: {
         fileformat: {
            es: `Formato de archivo '%0' no es válido`,
            fr: `Le format de fichier '%0' n'est pas acceptable`,
            en: `The file format '%0' is not acceptable`,
         },
         upload: {
            es: 'Error de carga del servidor: ',
            fr: "Erreur pendant l'envoi au serveur : ",
            en: 'Server upload error: ',
         },
      },
   },
   notices: {
      login: {
         es: 'Conexión en curso...',
         fr: 'Connexion en cours...',
         en: 'Login in progress...',
      },
   },
   table: {
      rowsPerPage: {
         es: 'Filas por página',
         fr: 'Rangées par page',
         en: 'Rows per page',
      },
      rangeSeparator: {
         es: 'de',
         fr: 'sur',
         en: 'of',
      },
      nextButton: {
         es: 'Página siguiente',
         fr: 'Page suiv.',
         en: 'Next page',
      },
      prevButton: {
         es: 'Página anterior',
         fr: 'Page préc.',
         en: 'Previous page',
      },
      pageSize: {
         es: 'Establecer tamaño de página',
         fr: 'Taille de la page',
         en: 'Set page size',
      },
   },
   units: {
      days: {
         fr: 'Jours',
         en: 'Days',
         es: 'Dias',
      },
   },
   statuses: {
      environment: [
         {
            key: 'pending',
            label: {
               fr: 'Attente fichier',
               en: 'Pending file',
               es: 'Pendiente',
            },
         },
         {
            key: 'completed',
            label: {
               fr: 'Complet',
               en: 'Completed',
               es: 'Terminado',
            },
         },
         {
            key: 'deletable',
            label: {
               fr: `En cours d'effacement`,
               en: 'Planned deletion',
               es: 'Suprimible',
            },
         },
      ],
   },
}
