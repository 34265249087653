import React from 'react'
import {
   Form,
   Input,
   Select,
   Button,
   Icon,
   Checkbox,
   Container,
   Dimmer,
   Segment,
   Loader,
   Confirm,
   Progress,
   Header,
   Label,
   Accordion,
} from 'semantic-ui-react'
import _ from 'lodash'
import Dropzone from 'react-dropzone'
import firebase from 'firebase/app'
import 'firebase/storage'
import labels from '../Common/translations'
import { labelHelper, dureeFormat, dateFormat } from '../Helpers'
import { ErrorMessage } from '../Common'
import styled from 'styled-components'
import { BaaS } from '../..'
import SelectMany from './fields/selectmany'
import TextEditor from './fields/textEditor'
import FileField from './fields/file'

const StyledForm = styled.div`
   input: {
      color: rgba(0, 0, 0, 0.87);
   }
   div.text: {
      color: #000;
   }
   .ui.button:disabled {
      opacity: 0.85 !important;
   }
   .ui.label {
      margin: 3px;
   }
   .formGroup {
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 0.3rem;
      padding: 1% 1% 1% 1%;
      margin-bottom: 1%;
   }
   .m-bottom {
      margin-bottom: 2%;
   }
`

export default class FormComponent extends React.Component {
   static GROUP = 'group'
   static CHECKBOX = 'checkbox'
   static SELECT = 'select'
   static SELECTMANY = 'selectmany'
   static PASSWORD = 'passwords'
   static FILE = 'file'
   static MULTIFILE = 'multifile'
   static EMAIL = 'email'
   static DATE = 'date'
   static TIME = 'time'
   static NUMBER = 'number'
   static TEXTEDITOR = 'editor'

   static FORMAT_OBJECT = 'object'
   static FORMAT_DEFAULT = 'default'

   static MODE_CREATE = 'create'
   static MODE_UPDATE = 'update'
   static MODE_SEARCH = 'search'

   static DIMMER_TYPE_LOADER = 'loader'
   static DIMMER_TYPE_PROGRESS = 'progress'
   static DEFAULT_DIMMER_MESSAGE = labels.form.saving

   static REDIRECT_NONE = 'none'

   dataPrefix = 'data'
   readOnly = false
   group = ''
   isDimmed = false

   // temp path for the form uploaded medias
   tempPath = `tmp-${Date.now()}`

   constructor(props) {
      super(props)
      this.props = props
      this.isDimmed = this.props.dimmed || false

      this.state = {
         dimmed: this.isDimmed, // form is blurred while backend operation occurs
         dimmerType: FormComponent.DIMMER_TYPE_LOADER,
         dimmerMessage: FormComponent.DEFAULT_DIMMER_MESSAGE,
         deleteConfirm: false, // delete confirmation screen status
         error: null,
         errors: {},
         observers: [],
         keyPrefix: `form_${Date.now()}`, // fields key prefix to fully refresh form when needed
         cantSave: false, // whether save button is active
      }
      this.lb = new labelHelper(
         labels,
         this.props.authUser ? this.props.authUser.lang : labelHelper.lang
      )
      this.goBack = this.goBack.bind(this)
   }

   goBack() {
      this.props.history.goBack()
   }

   static getDerivedStateFromProps(props, state) {
      if (props.form && !state.formReady) {
         return FormComponent.initFormState(props, state)
      } else if (props.isDimmed && props.isDimmed !== state.dimmed) {
         return { dimmed: props.isDimmed }
      } else {
         return null
      }
   }

   /**
    * Inject form structure and data into state
    */
   static initFormState = (props, state) => {
      const extraState = {
         formReady: true,
         mode:
            props.form.mode !== undefined
               ? props.form.mode
               : props.data
               ? FormComponent.MODE_UPDATE
               : FormComponent.MODE_CREATE,
         data: FormComponent.initFieldsValues(props, state),
         observers: FormComponent.initObservers(props),
         originalData: props.data || {},
         ...props.form.extras,
      }

      return extraState
   }

   async componentDidMount() {
      // Prepare fields values from async functions
      this.initSelectValues(this.props.form.fields)

      // trigger observers
      const changes = await this.triggerObservers(this.state.data)
      if (changes !== undefined) {
         const { formData, ...others } = changes
         this.setState({ data: { ...this.state.data, ...formData }, ...others })
      }
   }

   /**
    * This is called before saving changed data in state
    * An observer can pass modified form data in formData and other states directly
    * @param {} data
    * @return {} changed data
    */
   triggerObservers = async (data, prevData = { data: {} }) => {
      if (this.state.observers && this.state.observers.length > 0) {
         const promises = this.state.observers.map(async observer => {
            if (data[observer.observable] !== prevData[observer.observable]) {
               // execute observer function and provide current state and handler
               const res = await observer.action(data)
               return res
            }
         })

         return Promise.all(promises).then(values =>
            values.reduce((acc, cur) => ({ ...acc, ...cur }))
         )
      }
   }

   /**
    * Parse all form fields to detect those based on external values
    * and call the given function to populate them with options
    * @param {Object} fields
    */
   initSelectValues(fields) {
      _.map(fields, (field, name) => {
         if (field.type === FormComponent.GROUP) {
            this.initSelectValues(field.fields)
         } else if (
            field.values &&
            field.values.source &&
            field.hidden !== true
         ) {
            field.values
               .source(this.props, this.state)
               .then(val => this.setState({ [name + 'Values']: val }))
         }
      })
   }

   /**
    * Concat all fields observers in an array
    * @param {Props} props
    * @return Array
    */
   static initObservers(props) {
      const { form } = props
      let observers = []
      _.map(form.fields, (field, name) => {
         if (field.type === FormComponent.GROUP) {
            observers = observers.concat(
               FormComponent.initObservers({ ...props, form: field })
            )
         } else {
            if (field.observers) {
               observers = observers.concat(field.observers)
            }
         }
      })

      return observers
   }

   /**
    * Prepare the fields state filled from data props or with empty values
    * @param {*} props
    * @param {*} state
    * @param {*} useData
    */
   static initFieldsValues(props, state, useData = true) {
      const { form, data = {} } = props
      let formFields = {
         // inject cloud or onpremise key
         ...(data && { uid: data.uid || data.objectId }),
         ...(data && data.objectId && { objectId: data.objectId }),
      }
      _.map(form.fields, (field, name) => {
         if (field.type === FormComponent.GROUP) {
            formFields = {
               ...formFields,
               ...FormComponent.initFieldsValues(
                  { ...props, form: field },
                  state,
                  useData
               ),
            }
         } else {
            if (data && data[name] !== undefined) {
               if (field.disabled === true) {
                  formFields[`__${name}`] = useData ? data[name] : ''
               } else {
                  formFields[name] = useData ? data[name] : ''
               }
            } else {
               // no value
               if (
                  field.type !== undefined &&
                  field.type === FormComponent.CHECKBOX
               ) {
                  formFields[name] =
                     field.options && field.options.defaultValue
                        ? field.options.defaultValue
                        : false
               } else {
                  if (field.options && field.options.defaultValue && useData) {
                     formFields[name] =
                        typeof field.options.defaultValue === 'function'
                           ? field.options.defaultValue(props, state)
                           : field.options.defaultValue
                  } else {
                     formFields[name] = ''
                  }
               }
            }
         }
      })

      return formFields
   }

   /**
    * Reset form fields
    */
   reset = () => {
      this.setState({
         [this.dataPrefix]: FormComponent.initFieldsValues(this.props),
      })
   }

   /**
    * Extract value from parameter that can be a function
    */
   getParameterValue = (parameter = null) => {
      if (typeof parameter === 'function') {
         return parameter(this.props, this.state)
      } else {
         return parameter
      }
   }

   getData = key => {
      if (!this.state || (this.prefix && !this.state[this.dataPrefix])) {
         return null
      }
      return this.dataPrefix
         ? this.state[this.dataPrefix][key]
         : this.state[key]
   }

   getOriginalData = key => {
      return this.state.originalData[key]
   }

   /**
    * Register new data in form state and call registered observers
    * @param {string|array} key field key, array if value is part of object
    * @param {*} val new value
    */
   setData = async (key, val) => {
      let data = this.state.data
      if (Array.isArray(key)) {
         // data must be stored as object
         val = { ...data[key[0]], [key.join('')]: val }
         key = key[0]
      }

      // trigger optional observers with future & current data
      const changes = await this.triggerObservers({ ...data, [key]: val }, data)
      const { formData, ...others } = changes || {}

      this.setState({
         data: { ...data, ...formData, [key]: val }, // data changed from UI
         ...others,
      })
   }

   resetData = key => {
      this.setData(key, this.getOriginalData(key))
   }

   redirect = (path, data) => {
      if (typeof path === 'string') {
         if (path === FormComponent.REDIRECT_NONE) {
            this.props.history.go(0)
         } else {
            this.props.history.push(path)
         }
      } else if (Array.isArray(path)) {
         // 0: path
         // 1: either object of key/values pairs or function
         if (typeof path[1] === 'function') {
            path[1] = path[1](data)
            console.log(path)
         }
         _.each(path[1], (val, key) => {
            path[0] = path[0].replace(`:${key}`, val)
         })
         this.props.history.push(path[0])
      } else {
         throw Error('Unable to redirect, unexpected value')
      }
   }

   getFormData = () => {
      // keep only form fields that are not disabled
      const fields = _.pickBy(
         this.state.data,
         (_val, key) => key.indexOf('__') === -1
      )

      if (this.state.mode !== FormComponent.MODE_SEARCH) {
         const keywords = this.getKeywords(this.props.form.fields)
         if (keywords.length > 0) {
            fields.keywords = keywords
         }
      }

      return fields
   }

   /**
    * Recursively extract sequential keywords from words in
    * flagged fields to allow to search them
    */
   getKeywords = (fields, keywords = []) => {
      _.map(fields, (field, name) => {
         if (field.type === FormComponent.GROUP) {
            keywords = this.getKeywords(field.fields, keywords)
         } else {
            if (field.searchable && field.searchable === true) {
               const val = this.state.data[name]
               if (val) {
                  val.toLowerCase()
                     .split(' ')
                     .forEach(word => {
                        let seq = ''
                        word
                           .split('')
                           .splice(0, 10)
                           .forEach(letter => {
                              seq += letter
                              keywords.push(seq)
                           })
                     })
               }
            }
         }
      })

      return [...new Set(keywords)] // deduplicate
   }

   onSubmit = async () => {
      if (this.validate()) {
         this.setState({ dimmed: true, dimmerType: 'loader' })

         const data = this.getFormData()

         try {
            if (
               this.props.onSubmit &&
               typeof this.props.onSubmit === 'function'
            ) {
               this.props.onSubmit(data)
            } else if (
               this.props.form.actions &&
               typeof this.props.form.actions[this.state.mode] === 'function'
            ) {
               await this.props.form.actions[this.state.mode](data)
               if (this.props.form.actions.redirect) {
                  this.redirect(this.props.form.actions.redirect, data)
               } else {
                  this.goBack()
               }
            } else {
               throw new Error(this.lb._('form.errors.missing_action'))
            }
         } catch (err) {
            const resp = err.response ? err.response.data : err
            const error = resp.message
               ? `${this.lb._('form.errors.server_error')} ${resp.message}${
                    resp.code ? `(code : ${resp.code})` : ''
                 }`
               : err.message
            this.setState({ dimmed: false, error })
         }
      }
   }

   validate = () => {
      const errors = this.validateFields(this.props.form.fields)
      if (_.size(errors) > 0) {
         const error = `
            ${this.lb._('form.errors.missing_values')}
            ${_.map(errors, err => this.lb._(err)).join(', ')}`
         this.setState({ errors, error })
         return false
      }

      this.setState({ errors, error: null })
      return true
   }

   validateFields = (fields, errors = {}) => {
      _.map(fields, (field, name) => {
         if (field.type === FormComponent.GROUP) {
            errors = this.validateFields(field.fields, errors)
         } else if (
            field.mandatory &&
            (field.mandatory === true || field.mandatory === this.state.mode) &&
            (!this.state.data[name] ||
               this.state.data[name] === '' ||
               (Array.isArray(this.state.data[name]) &&
                  this.state.data[name].length === 0))
         ) {
            errors[name] = field.label
         }
      })

      return errors
   }

   /**
    * Manage on change event on text and password fields
    * @param {Event} e
    */
   onChange = e => {
      this.setData(e.target.name, e.target.value)

      // if (e.target.type === 'password') {
      //    const originalName = e.target.name.split('__')[1]
      //    this.setData(originalName, e.target.value)
      // }
   }

   /**
    * Insert the selected value of a dropdown into the form data the right way
    * @param object _e the event (unused)
    * @param object data field data
    */
   onChangeSelect = (_e, data) => {
      if (!data.value) {
         return this.setData(data.name, null)
      }
      if (data.format && data.format === FormComponent.FORMAT_OBJECT) {
         this.setData(data.name, {
            ref: `${data.collection}/${data.value}`, // API ref
            label: _.find(data.options, o => o.value === data.value).text,
         })
      } else {
         this.setData(data.name, data.value)
      }
   }

   onChangeCheckbox = (_e, data) => this.setData(data.name, data.checked)

   onCancel = () => {
      if (this.props.onCancel) {
         this.props.onCancel(this.props, this.state)
      } else if (this.props.form.actions.redirect) {
         this.props.history.push(this.props.form.actions.redirect)
      } else {
         this.goBack()
      }
   }

   onDelete = () => {
      if (this.state.deleteConfirm === true) {
         try {
            if (
               this.props.onDelete &&
               typeof this.props.onDelete === 'function'
            ) {
               this.props.onDelete(this.state.data)
            } else if (
               this.props.form.actions.delete &&
               typeof this.props.form.actions.delete === 'function'
            ) {
               this.props.form.actions.delete(this.state.data)
               if (this.props.form.actions.redirect) {
                  this.props.history.push(this.props.form.actions.redirect)
               } else {
                  this.goBack()
               }
            }
            this.setState({ deleteConfirm: false })
         } catch (err) {
            this.setState({ error: err.message, deleteConfirm: false })
         }
      } else {
         this.setState({ deleteConfirm: true })
      }
   }

   /**
    * Get the disabled status of the given field in the given optional group
    * @param name {String} field name (original version)
    * @param group {String} field group
    * @return Boolean
    */
   isDisabled = (name, group) => {
      if (this.props.readOnly === true) {
         return true
      } else {
         const context = group ? this.props.form.fields[group] : this.props.form
         const field = context.fields[name]
         if (field) {
            return this.getParameterValue(field.disabled)
         } else {
            return false
         }
      }
   }

   /**
    * Get the protected status of the given field in the given optional group
    * @return Boolean
    */
   isProtected = (name, group) => {
      if (this.props.readOnly === true) {
         return true
      } else {
         const context = group ? this.props.form.fields[group] : this.props.form
         const field = context.fields[name]
         if (field) {
            return this.getParameterValue(field.protected)
         } else {
            return false
         }
      }
   }

   field = (
      name,
      label,
      type = 'text',
      allOptions = {},
      formatter = val => val
   ) => {
      const { href, label: labelOption, ...options } = allOptions
      const originalName = name.split('__')[1] || name
      if (type !== 'password') {
         options.defaultValue = formatter(this.getData(originalName))
         if (typeof options.defaultValue === 'object') {
            // This is typically a ref object used in a readonly form context
            options.defaultValue = options.defaultValue.label
         }
         if (options.value) {
            options.defaultValue = undefined
         }
      }

      if (type === FormComponent.TIME && options.value) {
         options.defaultValue = dureeFormat(options.value)
         type = 'text'
      }

      let action = null

      if (href) {
         // if an href function is given, add a right button to trigger it
         action = {
            color: 'blue',
            icon: 'linkify',
            onClick: () =>
               this.props.history.push(href(this.props, this.state)),
         }
      }

      if (this.state.mode !== FormComponent.MODE_SEARCH) {
         delete options.value
      }

      if (labelOption && typeof labelOption.content === 'object') {
         labelOption.content = this.lb._(labelOption.content)
      }

      return (
         <Form.Field key={this.getFieldKey(name)}>
            <label>{label}</label>
            <Input
               name={name}
               type={type}
               placeholder={label}
               onChange={this.onChange}
               readOnly={this.isDisabled(originalName, options.group)}
               error={this.state.errors[originalName] ? true : false}
               action={action}
               label={labelOption}
               {...options}
            />
         </Form.Field>
      )
   }

   /**
    * Return the correct key for the field matching name
    * @param {string} name Field Name
    * @returns string
    */
   getFieldKey = name => `${this.state.keyPrefix}_field_${name}`

   email = (name, label, options) => {
      return this.field(name, label, 'email', {
         ...options,
         control: 'email',
         label: '@',
      })
   }

   date = (name, label, options = {}) => {
      let type = 'date',
         formatter = v => v
      if (this.isDisabled(name) === true) {
         // unsafe if field in group
         formatter = ts => dateFormat(ts)
         type = 'text'
      } else {
         formatter = ts => (ts ? new Date(ts).toISOString().split('T') : ts)
      }

      return this.field(
         name,
         label,
         type,
         { ...options, control: 'date' },
         formatter
      )
   }

   time = (name, label, options) => {
      return this.field(name, label, 'time', { ...options })
   }

   password = (name, label, options) => {
      return this.field(name, label, 'password', options)
   }

   passwords = (name, label, options) => {
      const passwordHandler = e => {
         // display warning if pwd1 & pwd2 !== null && pw1 !== pwd2
         const p1 = this.getData(`__${name}__1`)
         const p2 = this.getData(`__${name}__2`)

         if (p1 !== '' && p2 !== '' && p1 !== p2) {
            this.setState({
               errors: { [name]: label },
               error: this.lb._('errors.passwordsMismatch'),
            })
            if (e.target.name === `__${name}__2`) {
               e.target.value = ''
               e.target.focus()
            }
         } else {
            this.setState({
               errors: {},
               error: null,
            })
            this.setData(name, e.target.value)
         }
      }

      return (
         !this.props.readOnly && (
            <Form.Group widths="equal" key={this.getFieldKey(name)}>
               {this.field(`__${name}__1`, label, 'password', {
                  ...options,
                  autoComplete: 'new-password',
                  onBlur: passwordHandler,
               })}
               {this.field(
                  `__${name}__2`,
                  `${this.lb._('form.password.confirm')} ${label}`,
                  'password',
                  {
                     ...options,
                     autoComplete: 'new-password',
                     onBlur: passwordHandler,
                  }
               )}
            </Form.Group>
         )
      )
   }

   /**
    * Render a dropdown field
    * @param string name
    * @param string | Object label
    * @param array possible values
    * @param string format Saving format of the fied, either key or reference
    * @param Object foptions other field options
    * @todo review function prototype, maybe in a distinct component
    */
   select = (
      name,
      label,
      values = [],
      format = FormComponent.FORMAT_DEFAULT,
      foptions = []
   ) => {
      let currentValue = this.getData(name)
      let options = [],
         collection = null

      if (!Array.isArray(values)) {
         collection = values.collection || values.prop
         values = this.getValues(values)
         if (currentValue !== null && typeof currentValue === 'object') {
            if (currentValue.ref && typeof currentValue.ref === 'string') {
               currentValue = currentValue.ref.split('/')[1]
            }
         }
      }

      if (Array.isArray(values)) {
         values.map(value =>
            options.push({
               key: value.key,
               text:
                  value.label instanceof Object
                     ? this.lb._(value.label)
                     : value.label,
               value: value.key,
            })
         )
      }

      // Whether to allow an empty value on the selector (default is true)
      if (!currentValue && typeof foptions.emptyValue === 'function') {
         if (foptions.emptyValue(this.props) === false && options.length > 0) {
            currentValue = options[0]
         }
      }

      return (
         <Form.Field key={this.getFieldKey(name)}>
            <label>{label}</label>
            <Select
               name={name}
               placeholder={`${this.lb._('form.select')} ${label}`}
               options={options}
               format={format} // whether to return a ref+label object or just a key
               collection={collection}
               onChange={foptions.onChange || this.onChangeSelect}
               value={
                  currentValue !== null && typeof currentValue === 'object'
                     ? currentValue.key
                     : currentValue
               }
               loading={values.length < 1}
               disabled={this.isDisabled(name, foptions.group)}
               error={this.state.errors[name] ? true : false}
               clearable
               search
            ></Select>
         </Form.Field>
      )
   }

   getValues = data => {
      // eslint-disable-next-line default-case
      switch (data.from) {
         case 'props':
            return this.props[data.prop] || []
         case 'state':
            return this.state[data.prop] || []
      }
   }

   checkbox = (name, label, { resetIfDisabled, group }) => {
      const disabled = this.isDisabled(name, group)
      const value = disabled && resetIfDisabled ? false : this.getData(name)
      const style = { padding: '15px', ...(disabled && { opacity: 0.25 }) }
      return (
         <Form.Field key={this.getFieldKey(name)}>
            <label>{label}</label>
            <span style={{ whiteSpace: 'nowrap' }}>
               <span style={style} aria-label="forbidden" role="img">
                  🚫
               </span>
               <Checkbox
                  style={{ paddingTop: '5px' }}
                  toggle
                  onChange={this.onChangeCheckbox}
                  checked={value}
                  name={name}
                  disabled={disabled}
                  error={this.state.errors[name]}
               />
               <span aria-label="allowed" style={style} role="img">
                  ✔️
               </span>
            </span>
         </Form.Field>
      )
   }

   button = (label, onClick, options = {}) => {
      const callback = () => onClick(this.props, this.state, this)
      if (options.icon) {
         let { icon, ...purgedOptions } = options
         if (label) {
            return (
               <Button
                  key={label}
                  icon
                  labelPosition={options.position || 'left'}
                  onClick={callback}
                  {...purgedOptions}
               >
                  <Icon name={icon} />
                  {label}
               </Button>
            )
         } else {
            return (
               <Button icon onClick={callback} {...purgedOptions}>
                  <Icon name={icon} />
               </Button>
            )
         }
      } else {
         return (
            <Button onClick={callback} {...options}>
               {label}
            </Button>
         )
      }
   }

   fileUploader = (files, name) => {
      if (files[0]) {
         const field = this.props.form.fields[name]
         const video = files[0]
         if (field.mimes && !field.mimes.includes(video.type)) {
            alert(this.lb._('upload.errors.fileformat', [video.type]))
            return false
         }
         this.setDimmer(FormComponent.DIMMER_TYPE_PROGRESS)
         const metadata = {
            contentype: video.type,
            name: video.name,
         }

         const folder = this.getParameterValue(
            this.props.form.fields[name].options.folder
         )

         const filePath = `${this.tempPath}/${name}/${video.name}`
         let ref, storage, uploadTask

         if (BaaS === 'firebase') {
            storage = firebase.storage()
            ref = storage.ref(folder)
            uploadTask = ref.child(filePath).put(video, metadata)
            uploadTask.on(
               'state_changed',
               snapshot => {
                  const dimmerMessage = Math.round(
                     (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                  )
                  this.setState({ dimmerMessage })
               },
               error => {
                  this.setDimmer(FormComponent.DIMMER_TYPE_LOADER)
                  alert(this.lb._('upload.errors.upload') + error.message)
               },
               () => {
                  uploadTask.snapshot.ref.getMetadata().then(data => {
                     console.log(data)
                     const {
                        bucket,
                        fullPath: ref,
                        contentType,
                        size,
                        md5Hash,
                     } = data
                     this.setData(name, {
                        bucket,
                        ref,
                        contentType,
                        size,
                        label: video.name,
                        // add data that will help API process the file
                        isTempFile: true,
                        tempPath: this.tempPath,
                        md5Hash,
                     })
                     this.setDimmer(FormComponent.DIMMER_TYPE_LOADER)
                  })
               }
            )
         } else if (BaaS === 'onpremise') {
            try {
               const { Storage } = require('../Common/OnPremise')
               storage = new Storage()
               ref = storage.ref(`${folder}/tmp/`)
               uploadTask = ref
                  .child(filePath)
                  .put(video, metadata)
                  .then(data => {
                     const { bucket, ref, contentType, size } = data
                     this.setData(name, {
                        isTempFile: true,
                        bucket,
                        ref,
                        contentType,
                        size,
                        label: video.name,
                     })
                     this.setDimmer(FormComponent.DIMMER_TYPE_LOADER)
                  })
            } catch (error) {
               console.log(error)
               this.setDimmer(FormComponent.DIMMER_TYPE_LOADER)
               alert(this.lb._('upload.errors.upload') + error.message)
            }
         }
      }
   }

   /**
    * Set the dimmer type, either loader or progress
    */
   setDimmer = dimmerType => {
      switch (dimmerType) {
         case FormComponent.DIMMER_TYPE_PROGRESS:
            this.setState({
               dimmed: true,
               dimmerType,
               dimmerMessage: 0,
            })
            break

         case FormComponent.DIMMER_TYPE_LOADER:
            this.setState({
               dimmed: false,
               dimmerType,
               dimmerMessage: FormComponent.DEFAULT_DIMMER_MESSAGE,
            })
            break

         default:
            throw Error(`Unknown dimmer type: ${dimmerType}`)
      }
   }

   fileupload = (name, label, options) => {
      const file = this.getData(name)
      const original = this.getOriginalData(name)
      let subLabel

      if (file) {
         if (this.isProtected(name)) {
            return (
               <Form.Field key={`field_${name}`}>
                  <label>{label}</label>
                  <Icon name="file" /> {file.label}
               </Form.Field>
            )
         }
      }

      if (original) {
         subLabel = ` [${this.lb._('form.file.current')} : ${original.label}]`
      }

      if (file && original && file.label !== original.label) {
         return (
            <Form.Field key={`field_${name}`}>
               <label>
                  {label}
                  {subLabel && (
                     <Label size="tiny">
                        <Icon name="info" />
                        {subLabel}
                     </Label>
                  )}
               </label>
               <FileLabel
                  label={file.label}
                  onClick={() => this.resetData('file')}
               />
            </Form.Field>
         )
      } else {
         return (
            <Form.Field key={`field_${name}`}>
               <label>
                  {label}
                  {subLabel && (
                     <Label size="tiny">
                        <Icon name="info" />
                        {subLabel}
                     </Label>
                  )}
               </label>
               {file && !original && <FileLabel label={file.label} />}
               {(!file || original) && (
                  <Dropzone
                     onDrop={acceptedFiles =>
                        this.fileUploader(acceptedFiles, name)
                     }
                  >
                     {({ getRootProps, getInputProps }) => (
                        <section>
                           <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <Label as="a" size="large" color="green">
                                 <Icon name="file" />
                                 {this.lb._('form.file.upload')}
                              </Label>
                           </div>
                        </section>
                     )}
                  </Dropzone>
               )}
            </Form.Field>
         )
      }
   }

   defaultOptions = {
      disabled: false,
      extras: [],
   }

   buttons = (
      onSubmit,
      onCancel,
      disabled = false,
      options = this.defaultOptions
   ) => {
      return this.props.readOnly ? (
         <div></div>
      ) : (
         <div>
            {this.button(
               this.lb._(options.submit.label || 'form.submit'),
               onSubmit,
               {
                  icon: options.submit.icon || 'save',
                  primary: this.state.canSave,
                  disabled: this.state.cantSave,
               }
            )}
            {options.cancel &&
               this.button(
                  this.lb._(options.cancel.label || 'form.cancel'),
                  onCancel,
                  {
                     icon: options.cancel.icon || 'ban',
                  }
               )}
            {this.state.mode === FormComponent.MODE_UPDATE &&
               (this.props.onDelete ||
                  this.props.form.buttons.delete === true) &&
               this.button(this.lb._('form.delete'), this.onDelete, {
                  icon: 'delete',
                  negative: true,
               })}
            {(this.props.onDelete || this.props.form.actions.delete) &&
               this.deleteModal()}
            {options.extras.map(
               button =>
                  (!button.hidden ||
                     button.hidden(this.props, this.state) !== true) &&
                  this.button(
                     this.lb._(button.label),
                     button.callback,
                     button.options
                  )
            )}
         </div>
      )
   }

   deleteModal = () => (
      <Confirm
         open={this.state.deleteConfirm}
         header={this.lb._('delete.header')}
         content={this.lb._('delete.content')}
         cancelButton={this.lb._('delete.cancel')}
         confirmButton={this.lb._('delete.confirm')}
         onCancel={() => this.setState({ deleteConfirm: false })}
         onConfirm={this.onDelete}
      />
   )

   render() {
      return (
         <StyledForm>
            <Container>
               <Dimmer.Dimmable
                  as={Segment}
                  blurring
                  dimmed={this.state.dimmed}
               >
                  {this.renderDimmer(this.state.dimmerType)}
                  <Form>
                     {this.state.error && (
                        <ErrorMessage content={this.state.error} />
                     )}
                     {this.renderFields(this.props.form.fields)}
                     {this.renderButtons(this.props.form.buttons)}
                  </Form>
               </Dimmer.Dimmable>
            </Container>
         </StyledForm>
      )
   }

   renderDimmer = (type = 'loader') => {
      return (
         <Dimmer active={this.state.dimmed} page inverted>
            {type === 'loader' && (
               <Loader size="massive">
                  {this.lb._(this.state.dimmerMessage)}
               </Loader>
            )}
            {type === 'progress' && (
               <Container>
                  <Header as="h1" color="black" icon>
                     <Icon name="upload" />
                     {this.lb._('upload.header')}
                     <Header.Subheader>
                        {this.lb._('upload.subheader')}
                     </Header.Subheader>
                  </Header>
                  <Progress
                     percent={
                        // cover the use case of upload percent (number)
                        typeof this.state.dimmerMessage === 'string'
                           ? this.lb._(this.state.dimmerMessage)
                           : this.state.dimmerMessage
                     }
                     progress
                     color="red"
                  />
               </Container>
            )}
         </Dimmer>
      )
   }

   renderFields(fields, group) {
      return _.map(fields, (field, name) => {
         return field.type === FormComponent.GROUP
            ? this.renderGroup(field, name)
            : this.renderField(field, name, group)
      })
   }

   renderAccordion = (group, name) => {
      const panels = () => {
         let label = ''

         if (labelHelper.lang === 'en') {
            label = group.label.en
         } else if (labelHelper.lang === 'fr') {
            label = group.label.fr
         }

         return [
            {
               key: label,
               title: label,
               content: {
                  content: (
                     <Form.Group key={name} widths="equal">
                        {this.renderFields(group.fields, name)}
                     </Form.Group>
                  ),
               },
            },
         ]
      }

      return <Accordion panels={panels(group, name)}></Accordion>
   }

   renderNoTitleGroup = (group, name) => {
      return (
         <Form.Group key={name} widths="equal">
            {this.renderFields(group.fields, name)}
         </Form.Group>
      )
   }

   renderGroup(group, name) {
      return (
         <Container
            key={name}
            className={group.label !== undefined ? 'formGroup' : 'm-bottom'}
         >
            {group.label !== undefined
               ? this.renderAccordion(group, name)
               : this.renderNoTitleGroup(group, name)}
         </Container>
      )
   }

   renderField(field, name, group) {
      if (this.getParameterValue(field.hidden) === true) {
         return null
      }

      const originalName = name

      // rename disabled field so their value is never sent back
      if (field.disabled === true) {
         name = `__${name}`
      }

      if (group) {
         if (!field.options) field.options = {}
         field.options.group = group
      }

      let label = this.lb._(field.label)
      if (Array.isArray(field.labelVariables)) {
         field.labelVariables.map(
            (val, i) => (label = label.replace(`%${i}`, val))
         )
      }

      if (field.labelSuffix) {
         label = `${label} ${field.labelSuffix}`
      }

      switch (field.type) {
         case FormComponent.PASSWORD:
            return field.login
               ? this.password(name, label, field.options)
               : this.passwords(name, label, field.options)

         case FormComponent.SELECT:
            return this.select(
               name,
               label,
               field.values,
               field.return,
               field.options
            )

         case FormComponent.SELECTMANY:
            console.log(this.state[`${originalName}Values`])
            return (
               <Form.Field key={`field_${name}`}>
                  <label>{label}</label>
                  <SelectMany
                     name={name}
                     options={
                        this.state[`${originalName}Values`]
                           ? this.state[`${originalName}Values`]
                           : []
                     }
                     placeholder={label}
                     defaultValue={this.getData(name)}
                     disabled={field.disabled}
                     onChange={(_ev, { value }) => this.setData(name, value)}
                     loading={field.options && field.options.loading}
                  />
               </Form.Field>
            )

         case FormComponent.TEXTEDITOR:
            return (
               <Form.Field key={`field_${name}`}>
                  <label>{label}</label>
                  <TextEditor
                     name={name}
                     defaultValue={this.getData(name)}
                     onChange={value => this.setData(name, value)}
                     languages={field.options.languages}
                     toolbar={field.options.toolbar}
                     lang={this.props.authUser.lang}
                  />
               </Form.Field>
            )

         case FormComponent.CHECKBOX:
            return this.checkbox(name, label, field.options)

         case FormComponent.FILE:
            return this.fileupload(name, label, field.options)

         case FormComponent.MULTIFILE:
            return (
               <Form.Field key={`field_${name}`}>
                  <label>{label}</label>
                  <FileField
                     name={name}
                     mimes={field.mimes}
                     defaultValue={this.getData(name)}
                     onChange={(name, value) => this.setData(name, value)}
                     options={field.options}
                     lang={this.props.authUser.lang}
                     protected={this.isProtected(name)}
                     parent={this}
                  />
               </Form.Field>
            )

         case FormComponent.EMAIL:
            return this.email(name, label, field.options)

         case FormComponent.DATE:
            return this.date(name, label, field.options)

         case FormComponent.TIME:
            return this.time(name, label, field.options)

         default:
            return this.field(
               name,
               label,
               field.type || 'text',
               field.options,
               field.formatter
            )
      }
   }

   renderButtons(options = {}) {
      return (
         <div>{this.buttons(this.onSubmit, this.onCancel, false, options)}</div>
      )
   }
}

const FileLabel = props => (
   <Label as="a" size="large" color="orange" onClick={() => props.onClick}>
      <Icon name="file" />
      {props.label}
   </Label>
)
