import React from 'react'
import Dropzone from 'react-dropzone'
import firebase from 'firebase/app'
import 'firebase/storage'
import { Form, Icon, Label, Progress } from 'semantic-ui-react'
import AbstractField from './abstractField'
import { dureeFormat } from '../../Helpers'

const RUNNING = 'running'
const COMPLETED = 'completed'
const FAILED = 'failed'
const CANCELLED = 'cancelled'

export default class File extends AbstractField {
   constructor(props) {
      super(props)
      this.state = { processes: [], counter: 0 }
   }

   fileUploader = (files, name) => {
      if (Array.isArray(files)) {
         files.map(async file => {
            if (
               this.props.options.mimes &&
               !this.props.options.mimes.includes(file.type)
            ) {
               alert(this.lb._('upload.errors.fileformat', [file.type]))
               return false
            }
            const metadata = {
               contentype: file.type,
               name: file.name,
            }

            const folder = this.props.parent.getParameterValue(
               this.props.options.folder
            )

            const position = await this.createProcess(file)

            const filePath = `${this.props.parent.tempPath}/${name}${position +
               1}/${file.name}`
            let ref, storage, uploadTask

            // temporarly disable parent saving capacity
            this.props.parent.setState({ cantSave: true })

            storage = firebase.storage()
            ref = storage.ref(folder)
            uploadTask = ref.child(filePath).put(file, metadata)
            uploadTask.on(
               'state_changed',
               snapshot => {
                  this.updateProcess(position, {
                     progress: snapshot.bytesTransferred / snapshot.totalBytes,
                  })
               },
               error => {
                  alert(this.lb._('upload.errors.upload') + error.message)
               },
               () => {
                  // retrieveuploaded file data
                  uploadTask.snapshot.ref.getMetadata().then(meta => {
                     const { bucket, fullPath: ref, contentType, size } = meta
                     const data = {
                        bucket,
                        ref,
                        contentType,
                        size,
                        label: file.name,
                        tempPath: this.props.parent.tempPath,
                     }
                     this.updateProcess(position, { status: COMPLETED, data })
                     this.props.parent.setState({ cantSave: false })
                  })
               }
            )
         })
      }
   }

   /**
    * Create new upload process and add it to stack
    * @param {object} file
    * @returns number
    */
   async createProcess(file) {
      const { name, size } = file
      const processes = this.state.processes || []
      processes.push({
         name,
         size: Math.round(size / (1024 * 1024)), // in MB
         progress: 0,
         startedAt: Date.now(),
         elapsed: 0,
         bandwidth: 1,
         remaining: 0,
         status: RUNNING,
         data: {},
      })
      this.setState({ processes })

      return processes.length - 1
   }

   /**
    * Update process with new properties
    * @param {number} index
    * @param {object} content
    */
   updateProcess(index, content = {}) {
      const processes = this.state.processes || []
      const process = processes[index]

      // elapsed time in seconds
      const elapsed = (Date.now() - process.startedAt) / 1000

      // bandwidth (Megabytes / sec)
      const bandwidth = Math.round((process.size * process.progress) / elapsed)

      // remaining upload time (in seconds)
      const remaining = Math.round(
         (process.size * (1 - process.progress)) / bandwidth
      )

      processes[index] = {
         ...process,
         ...content,
         elapsed,
         bandwidth,
         remaining,
      }
      // regenerate form value on specific status
      if (processes[index].status.includes(COMPLETED, CANCELLED)) {
         this.sendData(processes)
      }
      this.setState(processes)
   }

   sendData(processes) {
      this.props.onChange(this.props.name, {
         // add data that will help API process the file
         isTempFile: true,
         tempPath: this.props.parent.tempPath,
         files: processes
            .filter(process => process.status === COMPLETED)
            .map(({ data }) => data),
      })
   }

   fileLabel = props => (
      <Label as="a" size="large" color="orange" onClick={() => props.onClick}>
         <Icon name="file" />
         {props.label}
      </Label>
   )

   loaders = () =>
      this.state.processes.map((process, index) => {
         let color, icon
         switch (process.status) {
            case COMPLETED:
               color = 'green'
               icon = 'check'
               break
            case FAILED:
               color = 'red'
               icon = 'cancel'
               break
            case CANCELLED:
               color = 'grey'
               icon = 'delete'
               break
            case RUNNING:
            default:
               color = 'blue'
               icon = 'cloud upload'
               break
         }
         return (
            <Progress
               key={index}
               percent={Math.round(process.progress * 100)}
               progress
               color={color}
               disabled={process.status === CANCELLED}
            >
               <Icon name={icon} /> {index + 1} - {process.name}
               <Label as="a" color={color}>
                  <Icon name="file" />
                  {process.size}MB
               </Label>
               <Label as="a" color={color}>
                  <Icon name="tachometer alternate" />
                  {process.bandwidth}MB/s
               </Label>
               {process.remaining > 0 && (
                  <Label as="a">
                     <Icon name="clock outline" />
                     {dureeFormat(process.remaining * 1000)}
                  </Label>
               )}
               {process.status === COMPLETED && (
                  <Icon
                     name="cancel"
                     link
                     color="red"
                     onClick={() =>
                        this.updateProcess(index, { status: CANCELLED })
                     }
                  />
               )}
            </Progress>
         )
      })

   render() {
      let subLabel
      const { name, label, file, original } = this.props

      if (file) {
         if (this.props.protected) {
            return (
               <Form.Field key={`field_${name}`}>
                  <label>{label}</label>
                  <Icon name="file" /> {file.label}
               </Form.Field>
            )
         }
      }

      if (original) {
         subLabel = ` [${this.lb._('form.file.current')} : ${original.label}]`
      }

      if (file && original && file.label !== original.label) {
         return (
            <Form.Field key={`field_${name}`}>
               <label>
                  {label}
                  {subLabel && (
                     <Label size="tiny">
                        <Icon name="info" />
                        {subLabel}
                     </Label>
                  )}
               </label>
               <this.fileLabel
                  label={file.label}
                  onClick={() => this.resetData('file')}
               />
            </Form.Field>
         )
      } else {
         return (
            <Form.Field key={`field_${name}`}>
               <label>
                  {label}
                  {subLabel && (
                     <Label size="tiny">
                        <Icon name="info" />
                        {subLabel}
                     </Label>
                  )}
               </label>
               {file && !original && <this.fileLabel label={file.label} />}
               {(!file || original) && (
                  <Dropzone
                     onDrop={acceptedFiles =>
                        this.fileUploader(acceptedFiles, name)
                     }
                  >
                     {({ getRootProps, getInputProps }) => (
                        <section>
                           <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <Label as="a" size="large" color="green">
                                 <Icon name="file" />
                                 {this.lb._('form.file.upload')}
                              </Label>
                           </div>
                        </section>
                     )}
                  </Dropzone>
               )}
               <this.loaders />
            </Form.Field>
         )
      }
   }
}
