import React from 'react'
import { withAuthorization, withAuthentication } from '../Session'
import { compose } from 'recompose'
import * as ROUTES from '../../constants/routes'
import { Switch } from 'react-router-dom'
import EnvironmentShowPage from './show'
import EnvironmentTreePage from './tree'
import { Container, Modal, Tab, Divider } from 'semantic-ui-react'
import { TabWidget } from '../Widgets'
import TableComponent from '../Table'
import SearchComponent from '../Form/search'
import search from './config/search'
import envList from './config/environments-list'
import { AutoHeader, MandatoryWarning, Page } from '../Common'
import SearchableList from '../Common/searchableList'
import { ROLES } from '../../constants'
import { labelHelper } from '../Helpers'
import labels from '../Common/translations'

const EnvironmentPage = () => (
   <div>
      <Switch>
         <Page
            exact
            path={ROUTES.ENVIRONMENTS}
            component={EnvironmentList}
            title="screens.environments"
         />
         <Page
            path={ROUTES.ENVIRONMENTS_SHOW}
            component={EnvironmentShowPage}
            title="screens.environment_show.details"
         />
         <Page
            path={ROUTES.ENVIRONMENTS_TREEVIEW}
            component={EnvironmentTreePage}
            title="screens.environment_show.details"
         />
      </Switch>
   </div>
)

class EnvironmentListBase extends SearchableList {
   constructor(props) {
      super(props)
      this.state = {
         loading: true,
         open: false,
      }
      this.modalRef = React.createRef()
   }

   lb = new labelHelper(
      labels,
      this.props.authUser ? this.props.authUser.lang : labelHelper.lang
   )

   close = () => this.setState({ open: false })

   panes = [
      {
         key: 'completed',
         menuItem: this.lb._(labels.screens.completedEnvironment),
         render: () => (
            <Tab.Pane key="completed">
               {this.shouldRender() ? (
                  <TableComponent
                     list={envList}
                     {...this.props}
                     filters={{ ...this.filters(), status: ['completed'] }}
                     modal={this.modalRef}
                     parent={this}
                  />
               ) : (
                  <MandatoryWarning />
               )}
            </Tab.Pane>
         ),
      },
      {
         key: 'pending',
         menuItem: this.lb._(labels.screens.pendingEnvironment),
         render: () => (
            <Tab.Pane key="pending">
               {this.shouldRender() ? (
                  <TableComponent
                     list={envList}
                     {...this.props}
                     filters={{
                        ...this.filters(),
                        status: ['completed', '!='],
                     }}
                     modal={this.modalRef}
                     parent={this}
                  />
               ) : (
                  <MandatoryWarning />
               )}
            </Tab.Pane>
         ),
      },
   ]

   render() {
      return (
         <Container>
            <Container>
               <AutoHeader />
            </Container>
            <SearchComponent
               form={search}
               data={this.filters()}
               {...this.props}
               propagate={filters => this.filters(filters)}
            />
            <Modal
               open={this.state.open}
               ref={this.modalRef}
               closeIcon
               content={this.state.content}
               onClose={this.close}
               closeOnEscape={false}
               header={this.state.modalHeader}
            />
            <Divider hidden />
            <TabWidget panes={this.panes} />
         </Container>
      )
   }

   /**
    * Should the content be rendered ?
    * if user is partner, they must choose a company
    */
   shouldRender() {
      return (
         (this.filters() && this.filters().company) ||
         [ROLES.ROLE_ROOT, ROLES.ROLE_ADMIN].includes(this.props.authUser.role)
      )
   }
}

const condition = authUser => authUser // && !!authUser.roles[ROLES.ADMIN]

const EnvironmentList = withAuthentication(EnvironmentListBase)

export default compose(withAuthorization(condition))(EnvironmentPage)
