import React from 'react'
import AuthUserContext from './context'
import { withFirebase } from '../Firebase'
import { BaaS } from '../..'
import { compose } from 'recompose'
import { ROUTES } from '../../constants'

const withAuthentication = Component => {
   class WithAuthentication extends React.Component {
      listener = null
      constructor(props) {
         super(props)
         this.state = {
            authUser: JSON.parse(localStorage.getItem('authUser')),
         }
      }

      async componentDidMount() {
         if (BaaS === 'firebase') {
            this.listener = this.props.firebase.onAuthUserListener(
               authUser => {
                  // next function
                  localStorage.setItem('authUser', JSON.stringify(authUser))
                  this.setState({ authUser })
               },
               () => {
                  // fallback function
                  localStorage.removeItem('authUser')
                  this.setState({ authUser: null })
                  if (
                     !ROUTES.NO_AUTH_ROUTES.includes(
                        window.location.pathname,
                     ) &&
                     window.location.pathname.substr(0, 11) !== '/on-boarding'
                  ) {
                     // redirect user to signin
                     window.location.replace(ROUTES.SIGN_IN)
                  }
               },
            )
         } else {
            // on premise
            const Parse = require('parse')
            Parse.initialize('backoffice', 'totoro', 'totoro')
            Parse.serverURL = 'http://localhost:1337/parse'
            const user = Parse.User.current()
            if (user) {
               const query = new Parse.Query('users')
               query.equalTo('authId', user.id)
               const record = await query.first()
               const authUser = { ...record.toJSON(), uid: record.id }
               localStorage.setItem('authUser', JSON.stringify(authUser))
               this.setState({ authUser })
            }
         }
      }

      componentWillUnmount() {
         if (typeof this.listener === 'function') this.listener()
      }

      render() {
         return (
            <AuthUserContext.Provider value={this.state.authUser}>
               <Component {...this.props} authUser={this.state.authUser} />
            </AuthUserContext.Provider>
         )
      }
   }

   return compose(withFirebase)(WithAuthentication)
}

export default withAuthentication
