export const LANDING = '/'
export const SIGN_OUT = '/signout'
export const SIGN_IN = '/signin'
export const HOME = '/home'
export const ACCOUNT = '/account'
export const ADMIN = '/admin'
export const PASSWORD_FORGET = '/pw-forget'
export const PASSWORD_EMAIL = '/pw-email'
export const ONBOARDING = '/onboarding/:id'
export const ONBOARDING_GUEST = '/onboarding/:type/:hash'

export const UNITY_LOGIN = '/login'

export const USER = '/user'
export const USER_CREATE = '/user/create'
export const USER_INVITE = '/user/invite'
export const USER_SHOW = '/user/show/:id'
export const USER_SHOW_TAB_DETAILS = '/user/show/:id/#details'
export const USER_SHOW_TAB_SESSIONS = '/user/show/:id/#sessions'
export const USER_MODIFY = '/user/modify/:id'
export const USER_RECORD = '/user/record'

export const SESSIONS = '/sessions'
export const SESSIONS_SHOW = '/sessions/show/:id'

export const ENVIRONMENTS = '/environments'
export const ENVIRONMENTS_SHOW = '/environments/show/:id'
export const ENVIRONMENTS_TREEVIEW = '/environments/:pid/versions/:id/tree'
export const ENVIRONMENTS_SHOW_TAB_DETAILS = '/environments/show/:id/#details'
export const ENVIRONMENTS_SHOW_TAB_VERSIONS = '/environments/show/:id/#versions'
export const ENVIRONMENTS_SHOW_TAB_SCENES = '/environments/show/:id/#scenes'
export const ENVIRONMENTS_SHOW_TAB_MEDIAS = '/environments/show/:id/#medias'

export const COMPANY = '/company'
export const COMPANY_ADMIN = '/mycompany'
export const COMPANY_CREATE = '/company/create'
export const COMPANY_SHOW = '/company/show/:id'
export const COMPANY_SHOW_TAB_DETAILS = '/company/show/:id/#details'
export const COMPANY_SHOW_TAB_USERS = '/company/show/:id/#users'
export const COMPANY_SHOW_TAB_GROUPS = '/company/show/:id/#groups'
export const COMPANY_SHOW_TAB_GUESTS = '/company/show/:id/#guests'
export const COMPANY_SHOW_TAB_VIDEOS360 = '/company/show/:id/#videos360'
export const COMPANY_SHOW_TAB_SCENES = '/company/show/:id/#scenes'
export const COMPANY_SHOW_TAB_SESSIONS = '/company/show/:id/#sessions'
export const COMPANY_MODIFY = '/company/modify/:id'
export const COMPANY_GROUPS = '/company/groups/:id'
export const COMPANY_GROUP_CREATE = '/company/:id/group/create'
export const COMPANY_GROUP_MODIFY = '/company/:pid/group/modify/:id'
export const COMPANY_PURCHASES = '/company/purchases/:id'
export const COMPANY_PURCHASE_CREATE = '/company/:id/purchase/create'

export const VIDEO = '/video360'
export const VIDEO_CREATE = '/video360/create'
export const VIDEO_JOB = '/video360/job'
export const VIDEO_SHOW = '/video360/show/:id'
export const VIDEO_MODIFY = '/video360/modify/:id'
export const VIDEO_VECTO = '/video360/vecto/:id'

export const SCENES = '/video3d'
export const SCENES_TAB_COMPLETED_SCENES = '/video3d/#completedScenes'
export const SCENES_TAB_PROGRESS_SCENES = '/video3d/#progressScenes'
export const SCENES_TAB_FAILED_SCENES = '/video3d/#failedScenes'
export const SCENE_SHOW = '/video3d/show/:pid/:id'
export const SCENE_SHOW_TAB_DETAILS = '/video3d/show/:pid/:id/#details'
export const SCENE_SHOW_TAB_SHARES = '/video3d/show/:pid/:id/#shares'
export const SCENE_SHOW_TAB_EVENTS = '/video3d/show/:pid/:id/#events'
export const SCENE_RENAME = '/video3d/rename/:pid/:id'

export const MEDIAS = '/medias'
export const MEDIAS_SHOW = '/medias/show/:id'
export const MEDIAS_MODIFY = '/medias/modify/:id'
export const MEDIAS_SHOW_TAB_SHARES = '/medias/show/:id/#shares'
export const MEDIAS_CREATE = '/medias/create'

export const ADMINISTRATION = '/administration'

export const TEMPLATES = '/templates'
export const TEMPLATES_CREATE = '/templates/create'
export const TEMPLATES_SHOW = '/templates/show/:id'
export const TEMPLATES_MODIFY = '/templates/modify/:id'

export const DOWNLOADS = '/downloads'
export const DOWNLOAD_CREATE = '/downloads/create'
export const DOWNLOAD_SHOW = '/downloads/show/:id'
export const DOWNLOAD_MODIFY = '/downloads/modify/:id'

export const NO_AUTH_ROUTES = [SIGN_IN, PASSWORD_FORGET]
