import FormComponent from '../../Form'
import { Stakeholders } from '../../Common'
import labels from '../../Common/translations'

export default {
   fields: {
      defaultAccessList: {
         label: labels.fields.default_access_list,
         type: FormComponent.SELECTMANY,
         mandatory: false,
         options: {
            defaultValue: [],
         },
         values: {
            from: 'state',
            prop: 'accessListValues',
            collection: 'roles',
            source: props => Stakeholders(props.data.company.ref),
         },
      },
   },
   buttons: {
      submit: true,
      extras: [],
   },
   actions: {
      update: ({ uid, ...data }) => null,
      redirect: FormComponent.REDIRECT_NONE,
   },
   extras: [],
}
