import Api from '../../Api'
import { dateFormat } from '../../Helpers'
import * as ROUTES from '../../../constants/routes'
import labels from '../../Common/translations'

export default {
   endpoint: 'templates',
   pagesize: 10,
   defaultSorting: { field: 'createdAt', order: 'desc' },
   columns: {
      name: {
         label: labels.fields.name,
         accessor: s => s.name,
      },
      type: {
         label: labels.fields.type,
         values: [
            {
               key: 'email',
               label: labels.fields.email_model,
            },
         ],
      },
      action: {
         label: labels.fields.action,
         values: {
            source: () => Api.selectValues('templatesActions'),
         },
      },
      createdAt: {
         label: labels.fields.created_at,
         format: row => dateFormat(row.createdAt),
      },
      createdBy: {
         label: labels.fields.created_by,
         accessor: s => s.createdBy && s.createdBy.label,
      },
   },
   buttons: {
      show: {
         label: labels.fields.show,
         url: ROUTES.TEMPLATES_SHOW,
         options: {
            icon: 'eye',
            positive: true,
         },
      },
      modify: {
         label: labels.fields.modify,
         url: ROUTES.TEMPLATES_MODIFY,
         options: {
            icon: 'edit',
            positive: true,
         },
      },
   },
   actions: {
      list: params => {
         return Api.instance().get('templates', params)
      },
   },
}
