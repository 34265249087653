import Api from '../../Api'
import { dateFormat } from '../../Helpers'
import * as ROUTES from '../../../constants/routes'
import labels from '../../Common/translations'

export default {
   endpoint: 'downloads',
   pagesize: 10,
   defaultSorting: { field: 'createdAt', order: 'desc' },
   columns: {
      name: {
         label: labels.fields.name,
         accessor: s => s.name,
      },
      versions: {
         label: labels.fields.version,
         accessor: s => s.version,
      },
      lang: {
         label: labels.fields.language,
         values: {
            source: () => Api.selectValues('languages'),
         },
      },
      accessible: {
         label: labels.fields.accessible,
         accessor: s => (s.accessible ? 'oui' : 'non'),
      },
      createdAt: {
         label: labels.fields.created_at,
         format: row => dateFormat(row.createdAt),
      },
   },
   buttons: {
      show: {
         label: labels.fields.show,
         url: ROUTES.DOWNLOAD_SHOW,
         options: {
            icon: 'eye',
            positive: true,
         },
      },
      modify: {
         label: labels.fields.modify,
         url: ROUTES.DOWNLOAD_MODIFY,
         options: {
            icon: 'edit',
            positive: true,
         },
      },
   },
   actions: {
      list: params => {
         params = {
            ...params,
            q: { status: ['active'] },
         }
         return Api.instance().get('downloads', params)
      },
   },
}
