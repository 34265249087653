import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import FormComponent from '../Form'
import {
   Container,
   Grid,
   Divider,
   Button,
   Icon,
   Tab,
   Modal,
} from 'semantic-ui-react'
import companyForm from './config/company-form'
import { withAuthentication } from '../Session'
import companyUserList from './config/company-users-list'
import companyGuestList from './config/company-guests-list'
import companyVideosList from './config/company-videos-list'
import companyScenesList from './config/company-scenes-list'
import companySessionList from './config/company-sessions-list'
import companyGroupList from './config/group/list'
import TableComponent from '../Table'
import { AutoHeader, AutoLabel } from '../Common'
import { labelHelper } from '../Helpers'
import labels from '../Common/translations'
import { TabWidget } from '../Widgets'

class CompanyShowPage extends React.Component {
   constructor(props) {
      super(props)
      this.state = { open: false, modalHeader: '' }
      this.modalRef = React.createRef()
      this.companyRef = `companies/${this.props.match.params.id}`
      this.goBack = this.goBack.bind(this)
   }

   lb = new labelHelper(
      labels,
      this.props.authUser ? this.props.authUser.lang : labelHelper.lang,
   )

   goBack() {
      this.props.history.goBack()
   }
   close = () => this.setState({ open: false })

   async componentDidMount() {
      this.setState({ loading: true })
      try {
         const item = await companyForm.actions.read(this.companyRef)
         this.setState({
            item: item.data,
            loading: false,
         })
      } catch (error) {
         throw new Error(`Couldn't get company data ${error}`)
      }
   }

   panes = [
      {
         key: 'details',
         menuItem: this.lb._('screens.detail'),
         render: () => (
            <Tab.Pane key="details">
               <Container className="main-form">
                  <FormComponent
                     form={companyForm}
                     readOnly={true}
                     data={this.state.item}
                     {...this.props}
                  />
               </Container>
            </Tab.Pane>
         ),
      },
      {
         key: 'users',
         menuItem: this.lb._('screens.users'),
         render: () => (
            <Tab.Pane key="users">
               <Container className="users-list">
                  <TableComponent
                     title={<AutoLabel labelKey="screens.users" />}
                     list={companyUserList}
                     filters={{
                        company: [this.companyRef, '==', 'ref'],
                        disabled: false,
                     }}
                     {...this.props}
                  />
               </Container>
            </Tab.Pane>
         ),
      },
      {
         key: 'groups',
         menuItem: this.lb._('screens.groups'),
         render: () => (
            <Tab.Pane key="groups">
               <Container className="groups-list">
                  <TableComponent
                     title={<AutoLabel labelKey="screens.groups" />}
                     list={companyGroupList}
                     baseRef={this.companyRef}
                     company={this.state.item}
                     parent={this}
                     modalHeader={
                        <AutoLabel labelKey="screens.guests_invite" />
                     }
                     {...this.props}
                  />
               </Container>
            </Tab.Pane>
         ),
      },
      {
         key: 'guests',
         menuItem: this.lb._('screens.guests'),
         render: () => (
            <Tab.Pane key="guests">
               <Container className="guests-list">
                  <TableComponent
                     title={<AutoLabel labelKey="screens.guests" />}
                     list={companyGuestList}
                     baseRef={this.companyRef}
                     {...this.props}
                  />
               </Container>
            </Tab.Pane>
         ),
      },
      {
         key: 'videos360',
         menuItem: this.lb._('screens.video360'),
         render: () => (
            <Tab.Pane key="videos360">
               <Container className="videos-list">
                  <TableComponent
                     title={<AutoLabel labelKey="screens.video360" />}
                     list={companyVideosList}
                     paginationOptions={[5]}
                     compact={true}
                     filters={{
                        company: [this.companyRef, '==', 'ref'],
                     }}
                     {...this.props}
                  />
               </Container>
            </Tab.Pane>
         ),
      },
      {
         key: 'scenes',
         menuItem: this.lb._('screens.video3d'),
         render: () => (
            <Tab.Pane key="scenes">
               <Container className="scenes-list">
                  <TableComponent
                     title={<AutoLabel labelKey="screens.video3d" />}
                     list={companyScenesList}
                     paginationOptions={[5]}
                     compact={true}
                     filters={{
                        company: [this.companyRef, '==', 'ref'],
                        status: 'completed',
                     }}
                     {...this.props}
                  />
               </Container>
            </Tab.Pane>
         ),
      },
      {
         key: 'sessions',
         menuItem: this.lb._('screens.sessions'),
         render: () => (
            <Tab.Pane key="sessions">
               <Container className="sessions-list">
                  <TableComponent
                     title={<AutoLabel labelKey="screens.sessions" />}
                     list={companySessionList}
                     paginationOptions={[5]}
                     compact={true}
                     filters={{
                        company: [this.companyRef, '==', 'ref'],
                     }}
                     {...this.props}
                  />
               </Container>
            </Tab.Pane>
         ),
      },
   ]

   render() {
      if (!this.state.item) {
         return <div />
      }
      return (
         <Container>
            <Grid columns="equal">
               <Grid.Column>
                  <AutoHeader params={[this.state.item.name]} />
               </Grid.Column>
               <Grid.Column textAlign="right">
                  <Button
                     primary
                     icon
                     labelPosition="left"
                     onClick={this.goBack}
                  >
                     <Icon name="left arrow" />
                     <AutoLabel labelKey="form.back" />
                  </Button>
               </Grid.Column>
            </Grid>
            <Divider hidden />
            <Modal
               open={this.state.open}
               ref={this.modalRef}
               closeIcon
               content={this.state.content}
               onClose={this.close}
               closeOnEscape={false}
               header={this.state.modalHeader}
            />
            <TabWidget panes={this.panes} />
         </Container>
      )
   }
}

export default compose(withRouter, withAuthentication)(CompanyShowPage)
