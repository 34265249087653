import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { compose } from 'recompose'
import * as ROUTES from '../../constants/routes'
import {
   Container,
   Header,
   Button,
   Icon,
   Divider,
   Tab,
} from 'semantic-ui-react'
import videoShow from './config/video3d-show'
import { AutoLabel } from '../Common'
import FormComponent from '../Form'
import TableComponent from '../Table'
import { withAuthentication } from '../Session'
import eventsList from './config/events-list'
import sharesList from './config/shares-list'
import { labelHelper } from '../Helpers'
import labels from '../Common/translations'
import { TabWidget } from '../Widgets'

class SceneShowPage extends React.Component {
   constructor(props) {
      super(props)
      this.state = {}
      this.goBack = this.goBack.bind(this)
      this.path = null
   }

   lb = new labelHelper(
      labels,
      this.props.authUser ? this.props.authUser.lang : labelHelper.lang,
   )

   async componentDidMount() {
      this.setState({ loading: true })
      const { id, pid } = this.props.match.params
      if (pid !== TableComponent.PID_PLACEHOLDER) {
         // Video3d is a child of a known Video360
         this.path = `${pid.replace('.', '/')}/vectos/${id}`
      } else {
         // Video3d is legacy element without a known parent
         this.path = `vectos/${id}`
      }
      console.log(this.path)
      const { data: item } = await videoShow.actions.read(this.path)
      console.log(item)
      this.setState({
         item,
         loading: false,
         id,
         pid,
      })
   }

   goBack() {
      this.props.history.goBack()
   }

   panes = [
      {
         key: 'details',
         menuItem: this.lb._(labels.screens.detail),
         render: () => (
            <Tab.Pane key="details">
               <FormComponent
                  form={videoShow}
                  data={this.state.item}
                  readOnly={true}
                  {...this.props}
               />
            </Tab.Pane>
         ),
      },
      {
         // TODO: [BO-201] Refactor baseRef to access 3D video shares
         key: 'shares',
         menuItem: this.lb._(labels.screens.video3d_shares),
         render: () => (
            <Tab.Pane key="shares">
               <TableComponent
                  title={<AutoLabel labelKey="screens.video3d_shares" />}
                  list={sharesList}
                  compact={true}
                  pagination={false}
                  baseRef={`${this.props.match.params.pid
                     .replace('videos', 'videos360')
                     .replace('.', '/')}/vectos/${this.props.match.params.id}`} //{this.path}
               />
            </Tab.Pane>
         ),
      },
      {
         key: 'events',
         menuItem: this.lb._(labels.screens.video3d_events),
         render: () => (
            <Tab.Pane key="events">
               <TableComponent
                  title={<AutoLabel labelKey="screens.video3d_events" />}
                  list={eventsList}
                  compact={true}
                  pagination={false}
                  data={Object.keys(this.state.item.events)
                     .sort()
                     .map(ts => ({
                        ts: parseInt(ts),
                        ...this.state.item.events[ts],
                     }))}
               />
            </Tab.Pane>
         ),
      },
   ]

   render() {
      if (!this.state.item) {
         return <div />
      }

      const {
         item: { status, name = {} },
      } = this.state

      return (
         <Container>
            <Container>
               <Header as="h1">{name}</Header>
               <Button primary icon labelPosition="left" onClick={this.goBack}>
                  <Icon name="left arrow" />
                  <AutoLabel labelKey="form.back" />
               </Button>
               {status === 'completed' && (
                  <Link
                     to={ROUTES.SCENE_RENAME.replace(
                        ':id',
                        this.state.id,
                     ).replace(':pid', this.state.pid)}
                  >
                     <Button icon labelPosition="left">
                        <Icon name="text cursor" />
                        Renommer
                     </Button>
                  </Link>
               )}
               <Divider hidden />
               <TabWidget panes={this.panes} />
            </Container>
         </Container>
      )
   }
}

export default compose(withRouter, withAuthentication)(SceneShowPage)
