import React from 'react'
import {
   dureeFormat,
   dateFormat,
   downloadUrl,
   labelFormat,
   sizeFormat,
} from '../../Helpers'
import Api from '../../Api'
import vectoStatus from '../../../models/vecto-status'
import { Container, Header } from 'semantic-ui-react'
import FormComponent from '../../Form'
import accessForm from './accesslist-form'
import labels from '../../Common/translations'

export default {
   endpoint: 'videos',
   pagesize: 10,
   defaultSorting: { field: 'createdAt', order: 'desc' },
   columns: {
      name: {
         label: labels.fields.name,
         accessor: s => `${s.name}`,
      },
      offsetStart: {
         label: labels.fields.start_offset,
         options: {
            transform: dureeFormat,
         },
         accessor: s => dureeFormat(s.offsetStart * 1000),
      },
      offsetEnd: {
         label: labels.fields.end_offset,
         accessor: s => dureeFormat(s.offsetEnd * 1000),
      },
      duration: {
         label: labels.fields.length,
         accessor: s => dureeFormat(s.duration * 1000),
         options: {
            maxWidth: '20px',
         },
      },
      size: {
         label: labels.fields.size,
         accessor: s =>
            sizeFormat(
               s.files && s.files.data && parseInt(s.files.data.size || 0),
            ),
         options: {
            maxWidth: '20px',
         },
      },
      status: {
         label: labels.fields.status,
         accessor: s => labelFormat(s.status, vectoStatus),
      },
      updatedAt: {
         label: labels.fields.latest_updated,
         accessor: s => dateFormat(s.updatedAt, true),
      },
      createdAt: {
         label: labels.fields.created_at,
         accessor: s => dateFormat(s.updatedAt, true),
      },
   },
   buttons: {
      download: {
         disabled: data => data.status !== 'completed',
         action: data => {
            if (data.status === 'completed') {
               window.open(downloadUrl(`${data.video.ref}-vecto-${data.uid}`))
            }
         },
         label: labels.fields.downloads,
         options: {
            icon: 'download',
            primary: true,
         },
      },
      access: {
         disabled: data => data.status !== 'completed',
         // display the contextual access list form in the screen modal
         // update the update action to provide the matching path
         action: async (data, props) => {
            accessForm.actions.update = ({ uid, ...data }) =>
               Api.instance().patch(`${props.baseRef}/vectos/${uid}`, data)

            // open modal with contextual form content
            props.parent.setState({
               open: true,
               content: (
                  <Container>
                     <Header as="h4" block>
                        {`Vecto ${data.name} (${data.company.label})`}
                     </Header>
                     <FormComponent form={accessForm} data={data} {...props} />
                  </Container>
               ),
            })
         },
         label: labels.fields.access_list,
         options: {
            icon: 'lock',
            primary: true,
         },
      },
      delete: {
         label: labels.fields.delete,
         options: {
            icon: 'delete',
            negative: true,
         },
         // passed params are current record data and page props
         action: ({ uid, video }) => {
            Api.instance()
               .delete(`${video.ref}/vectos/${uid}`)
               .then(() => window.location.reload())
               .catch(err => err.message)
         },
      },
   },
   actions: {
      list: (params, props) =>
         Api.instance().get(`${props.baseRef}/vectos`, params),
   },
}
