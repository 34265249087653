import { dateFormat, dureeFormat } from '../../Helpers'
import { ROLES, ROUTES } from '../../../constants'
import Api from '../../Api'
import labels from '../../Common/translations'

export default {
   endpoint: 'sessions',
   pagesize: 10,
   defaultSorting: { field: 'createdAt', order: 'desc' },
   columns: {
      company: {
         label: labels.fields.company,
         accessor: s => s.company.label,
         hidden: ({ authUser }) =>
            !ROLES.ROLES_WITH_COMPANIES.includes(authUser.role),
      },
      user: {
         label: labels.fields.user,
         accessor: row => row.user.label,
      },
      createdAt: {
         label: labels.fields.creation,
         accessor: row => dateFormat(row.createdAt),
      },
      duration: {
         label: labels.fields.duration,
         accessor: row => dureeFormat(row.duration),
      },
      // agent: {
      //    label: {
      //       fr: 'Agent',
      //       en: 'Agent',
      //    },
      //    accessor: row => row.agent.raw,
      // },
      os: {
         label: labels.fields.os,
         accessor: s => s.env.operatingSystem,
         options: {
            allowOverflow: 'true',
         },
      },
      location: {
         label: labels.fields.location,
         accessor: s =>
            `${s.city.charAt(0).toUpperCase() + s.city.slice(1)} (${
               s.country
            })`,
         options: {
            maxWidth: '10%',
         },
      },
   },
   buttons: {
      show: {
         label: labels.fields.show,
         url: ROUTES.SESSIONS_SHOW,
         options: {
            icon: 'eye',
            positive: true,
         },
      },
   },
   actions: {
      list: params => {
         return Api.instance().get('sessions', params)
      },
   },
}
