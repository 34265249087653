import FormComponent from '../../Form'
import Api from '../../Api'
import labels from '../../Common/translations'
import TextEditor from '../../Form/fields/textEditor'

export default {
   fields: {
      name: {
         label: labels.fields.name,
         mandatory: true,
      },
      version: {
         label: labels.fields.version,
         mandatory: false,
         options: {
            prefix: 'v',
         },
      },
      notes: {
         type: FormComponent.TEXTEDITOR,
         label: labels.fields.content,
         options: {
            languages: {
               fr: labels.fields.fr,
               en: labels.fields.en,
               es: labels.fields.es,
            },
            toolbar: TextEditor.TOOLBAR_MEDIUM,
         },
      },
      file: {
         type: 'file',
         label: labels.fields.file,
         mandatory: true,
         options: {
            folder: 'downloads',
            mimes: ['application/exe', 'application/x-msdownload'],
         },
         observers: [
            {
               // This observer resets the values of two stats fields
               // whenever the file is changed
               observable: 'file',
               action: (data, stateHandler) => {
                  if (
                     data.file &&
                     data.file.isTempFile &&
                     data.downloadCount > 0
                  ) {
                     return {
                        formData: {
                           downloadCount: 0,
                           downloadLast: null,
                        },
                     }
                  }
               },
            },
         ],
      },
      lang: {
         label: labels.fields.language,
         type: FormComponent.SELECT,
         values: {
            from: 'state',
            prop: 'langValues',
            collection: 'languages',
            source: () => Api.selectValues('languages', {}),
         },
         return: FormComponent.FORMAT_DEFAULT,
         mandatory: false,
      },
      accessible: {
         label: labels.fields.accessible,
         type: FormComponent.CHECKBOX,
         options: {
            defaultValue: false,
         },
      },
      downloadCount: {
         label: labels.fields.downloads,
         disabled: true,
      },
      downloadLast: {
         label: labels.fields.latest_download,
         type: FormComponent.DATE,
         disabled: true,
      },
   },
   buttons: {
      cancel: true,
      submit: true,
      delete: true,
      extras: [],
   },
   actions: {
      create: data => Api.instance().post('downloads/', data),
      read: uid => Api.instance().get(`downloads/${uid}`),
      update: ({ uid, ...data }) =>
         Api.instance().patch(`downloads/${uid}`, data),
      delete: ({ uid }) => Api.instance().delete(`downloads/${uid}`),
   },
   extras: [],
}
