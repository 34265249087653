import { dateFormat } from '../../Helpers'
import Api from '../../Api'
import { ROUTES } from '../../../constants'
import labels from '../../Common/translations'

const specialUsages = {
   '': '',
   icon: labels.fields.specialUsageValues.icon['fr'],
   preview: labels.fields.specialUsageValues.preview['fr'],
   overlayIcon: labels.fields.specialUsageValues.overlayIcon['fr'],
}

export default {
   endpoint: 'medias',
   pagesize: 1000,
   defaultSorting: { field: 'createdAt', order: 'desc' },
   columns: {
      media: {
         label: labels.fields.media,
         accessor: ({ media }) => `${media.name} (${media.company.label})`,
         options: {
            maxWidth: '30%',
         },
      },
      creator: {
         label: labels.fields.version,
         accessor: ({ version }) =>
            `${version.createdBy.label} (${version.company.label})`,
      },
      specialUsage: {
         label: labels.fields.specialUsage,
         accessor: ({ media }) => specialUsages[media.specialUsage || ''],
      },
      revision: {
         label: labels.fields.revision,
         accessor: ({ version }) => version.revision,
         options: {
            maxWidth: '5%',
         },
      },
      createdAt: {
         label: labels.fields.latest_updated,
         accessor: s => dateFormat(s.createdAt),
         options: {
            maxWidth: '10%',
         },
      },
   },
   buttons: {
      show: {
         label: labels.fields.show,
         url: ({ media }) => {
            const parts = media.ref.split('/')
            return ROUTES.MEDIAS_SHOW_TAB_SHARES.replace(':id', parts[1])
         },
         options: {
            icon: 'eye',
            positive: true,
         },
      },
   },
   actions: {
      list: (params, props) =>
         Api.instance().get(`${props.baseRef}/useMedias`, params),
   },
}
