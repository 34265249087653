const Minio = require('minio')
const Parse = require('parse')
Parse.initialize('backoffice', 'totoro', 'totoro')
Parse.serverURL = 'http://localhost:1337/parse'

export const FileObject = Parse.File

export const DataObject = Parse.Object

export const Query = Parse.Query

export class Storage {
   constructor(bucket = 'totalymage') {
      this.adapter = new Minio.Client({
         endPoint: 'localhost',
         port: 9000,
         useSSL: false,
         accessKey: 'minio',
         secretKey: 'minio123',
      })
      this.bucketName = bucket

      this.parse = require('parse')
      this.parse.initialize('backoffice', 'totoro', 'totoro')
      this.parse.serverURL = 'http://localhost:1337/parse'
   }

   ref(path) {
      return new StorageObject(path, this)
   }

   async setBucket() {
      try {
         if ((await this.adapter.bucketExists(this.bucketName)) !== true) {
            await this.adapter.makeBucket(this.bucketName)
         }
      } catch (err) {
         throw err
      }

      return this.bucketName
   }

   static encodePath(path) {
      return path.replace(/\//gi, '--')
   }

   static decodePath(path) {
      return path.replace(/--/gi, '/')
   }
}

export class StorageObject {
   constructor(ref, storage, parentRef = null) {
      this.ref = ref
      this.storage = storage
      this.parentRef = parentRef
      this.metadata = {}
   }

   child(path) {
      return new StorageObject(path, this.storage, this.ref)
   }

   put(file, meta = {}) {
      let path
      if (this.parentRef) {
         path = this.parentRef
         if (path.substring(path.length - 1) !== '/') {
            path += '/'
         }
      }
      path += this.ref

      return new Promise(async (resolve, reject) => {
         try {
            const parseFile = new this.storage.parse.File(
               Storage.encodePath(path),
               file,
               file.type,
               meta,
            )
            this.metadata = {
               bucket: this.storage.bucketName,
               ref: path,
               contentType: file.type,
               size: file.size,
            }
            await this.storage.setBucket()
            await parseFile.save()
            resolve(this.metadata)
         } catch (err) {
            reject(err.message)
         }
      })
   }

   getMetadata() {
      return this.metadata
   }

   async getDownloadURL() {
      return await this.storage.adapter.presignedGetObject(
         this.storage.bucketName,
         this.ref,
         24 * 60 * 60,
      )
   }
}
