// eslint-disable-next-line no-unused-vars
import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'
import axios from 'axios'
import hash from 'object-hash'
import { BaaS } from '../..'

interface RestApi {
   client: any
   mediaClient?: any
   params: object
}

enum Method {
   GET = 'GET',
   POST = 'POST',
   PATCH = 'PATCH',
   PUT = 'PUT',
   DELETE = 'DELETE',
}

type QueryOptions = {
   url?: string
   debug?: boolean
   batch?: number
   offset?: number
   where?: object
   headers?: object
   responseType?: string
}

type ApiPayload = {
   status: string
   data: ArrayLike<object>
   meta: object
}

type ServerResponse = {
   status: number
   data: ArrayLike<EnumValue>
}

type EnumValue = {
   uid?: string
   objectId?: string
   label?: string
   name?: string
}

const apiInstances: any = {}

export default class Api implements RestApi {
   [x: string]: any
   static debug: boolean = process.env.NODE_ENV === 'development'

   static DEFAULT: string = 'default'
   static DEFAULT_URL: string | undefined = ''
   static CACHE_ACTIVE = true
   static CACHE_TTL: number = 60 * 60
   static CACHE_REMOVE: string = '__force_cache_removal__'
   static registry: object = {}

   static instance = (url: string | null = null, name: string = Api.DEFAULT) =>
      apiInstances[name] || new Api(url)

   static cancelRequest = (key: string) => {
      // See how to use request cancellation if the origin component is dismounted
      // @see https://github.com/axios/axios#cancellation
   }

   static cache(key: string, data: any = undefined) {
      if (data) {
         if (data === Api.CACHE_REMOVE) {
            Api.invalidate(key)
         } else {
            sessionStorage.setItem(
               key,
               JSON.stringify({
                  data,
                  createdAt: Date.now(),
               })
            )
         }
         return data
      }

      let cached: any = localStorage.getItem(key)
      if (cached) {
         cached = JSON.parse(cached)
         if (Date.now() - cached.createdAt > Api.CACHE_TTL * 1000) {
            localStorage.removeItem(key)
         }
         return cached.data
      } else {
         return false
      }
   }

   static getCacheKey = (endpoint: string, options: any = null) =>
      `api-${endpoint}|${options ? hash(JSON.stringify(options)) : ''}`

   static invalidate = (prefix: string) => {
      prefix = prefix.split('/')[0]
      console.debug(`Removing all objects starting with key '${prefix}'`)
      for (let i = 0; i < sessionStorage.length; i++) {
         const entry: any = sessionStorage.key(i)
         console.debug(`testing '${entry}'...`)
         if (entry.startsWith(prefix)) {
            console.log('removing ' + entry)
            sessionStorage.removeItem(entry)
         }
      }
   }

   static selectValues = (endpoint: string, options: any = {}) => {
      Api.debug = options.debug || Api.debug
      options.offset = 0
      if (!options.batch) {
         options.batch = 100
      }

      const cacheKey = Api.getCacheKey(endpoint, options)

      if (Api.CACHE_ACTIVE === true) {
         const data = Api.cache(cacheKey)
         if (data !== false) {
            console.log(`CACHE HIT: ${endpoint}`)
            return new Promise(resolve => resolve(data))
         }
      }

      return Api.instance(options.url)
         .get(`values/${endpoint.replace(/\//g, '-')}`, options)
         .then((res: ServerResponse) => {
            const data = Object.entries(res.data).map(([, val]) => ({
               key: val.objectId || val.uid,
               label: val.label || val.name,
            }))

            Api.cache(cacheKey, data)
            return data
         })
         .catch((err: any) => 'Unable to get data from API: ' + err.message)
   }

   debug = false
   params: QueryOptions = {}
   client: any

   constructor(url: string | null, name: string = Api.DEFAULT) {
      this.client = axios.create({
         baseURL: url || Api.DEFAULT_URL + 'api',
         headers: {
            'Content-Type': 'application/json',
            'X-Parse-Application-Id': 'backoffice',
            'X-Client-ID': 'backoffice',
            //   crossdomain: true, // interdit par parse server
         },
      })

      this.mediaClient = axios.create({
         baseURL: url || Api.DEFAULT_URL,
         headers: {
            'X-Client-ID': 'backoffice',
            crossdomain: true,
         },
      })

      this.params = {
         offset: 0,
         batch: 10,
      }

      apiInstances[name] = this
   }

   post = (endpoint: string, payload: object) =>
      this.query(endpoint, Method.POST, payload)

   patch = (endpoint: string, payload: object) =>
      this.query(endpoint, Method.PATCH, payload)

   get = (endpoint: string, params: object = {}) =>
      this.query(endpoint, Method.GET, {}, params)

   delete = (endpoint: string, payload: object) =>
      this.query(endpoint, Method.DELETE, payload)

   getImage = (url: string, type = 'image/png') => {
      return new Promise((resolve, reject) => {
         app.auth().onAuthStateChanged(async authUser => {
            if (authUser) {
               this.mediaClient({
                  url,
                  method: 'GET',
                  headers: {
                     Authorization: 'Bearer ' + (await authUser.getIdToken()),
                  },
                  responseType: 'arrayBuffer',
               })
                  .then((res: any) => {
                     const blob = new Blob([res.data], {
                        type,
                     })
                     resolve(URL.createObjectURL(blob))
                  })
                  .catch((err: any) => reject(this.buildError(err)))
            }
         })
      })
   }

   query = (
      url: string,
      method: Method = Method.GET,
      payload: object = {},
      params: QueryOptions = {}
   ) => {
      if (method !== Method.GET) {
         // Remove all cached content for given endpoint which content will change
         console.log('Clearing local cache for ' + url)
         Api.cache(Api.getCacheKey(url, params).split('|')[0], Api.CACHE_REMOVE)
      }

      switch (BaaS) {
         case 'firebase':
            return new Promise((resolve, reject) => {
               app.auth().onAuthStateChanged(async authUser => {
                  if (authUser) {
                     this.logEvent(url, method, payload)
                     this.client({
                        url,
                        params,
                        method,
                        data: payload ? JSON.stringify(payload) : null,
                        headers: {
                           Authorization:
                              'Bearer ' + (await authUser.getIdToken()),
                           ...params.headers,
                        },
                     })
                        .then((res: any) => resolve(this.buildReturn(res)))
                        .catch((err: any) => reject(this.buildError(err)))
                  }
               })
            })
         case 'onpremise':
            return new Promise((resolve, reject) => {
               if ((url.match(/\//g) || []).length > 1) {
                  // query to a subcollection
                  const parts = url.split('/')
                  url = parts[2]
                  params = {
                     ...params,
                     where: { origin: `${parts[0]}/${parts[1]}` },
                  }
               }
               this.client({
                  url,
                  params,
                  method,
                  data: payload ? JSON.stringify(payload) : null,
               })
                  .then((res: any) => resolve(this.buildReturn(res)))
                  .catch((err: any) => reject(this.buildError(err)))
            })
         default:
            throw new Error(`Unknown BaaS value: '${BaaS}'`)
      }
   }

   /**
    * Log API Event in GA
    * @param endpoint
    * @param method
    * @param payload
    */
   logEvent = (endpoint: string, method: string, payload: any) => {
      if (method === Method.GET) {
         const [collection, uid, subcollection, sid] = endpoint.split('/')
         const eventName: any = sid || uid ? 'view_item' : 'view_item_list'
         app.analytics().logEvent(eventName, {
            content_type: subcollection || collection,
            content_id: sid || uid,
         })
      }
   }

   buildError = (error: any) => {
      console.debug('\tQuery Error:', error)
      return error
   }

   buildReturn = (response: any) => {
      const payload: ApiPayload = {
         status: response.status,
         data: [],
         meta: {},
      }
      const {
         data,
         config: { params },
      } = response

      if (Api.debug === true) {
         console.log(
            `API: [${
               response.status
            }] ${response.config.method.toUpperCase()} ${response.config.url}`
         )
         console.debug(`\tQuery parameters:`, params)
         console.debug(`\tResponse Payload:`, data)
      }

      /**
       * Response payload format
       * firebase: always an array of objects named items
       * parse: either an array of objects named results or a data object
       */
      if (data) {
         const { results, items } = data
         payload.data = this.preparePayload(results || items || data)
         payload.meta = { ...response.data.meta }
      }

      return payload
   }

   preparePayload = (payload: any) => {
      if (Array.isArray(payload)) {
         return payload.map(({ objectId, uid, ...others }) => ({
            uid: objectId || uid,
            ...others,
         }))
      } else {
         const { objectId, uid, ...others } = payload
         return { ...others, uid: objectId || uid }
      }
   }

   cacheKey = () => {
      return hash(this.params)
   }
}
