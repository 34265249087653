import firebase from 'firebase/app'
import 'firebase/functions'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/messaging'
import 'firebase/remote-config'
import { FirebaseConfig, BackendService } from '../../types'

class Firebase implements BackendService {
   useEmulator: boolean
   auth: firebase.auth.Auth
   db: firebase.firestore.Firestore
   storage: firebase.storage.Storage
   messaging: firebase.messaging.Messaging | null
   remoteConfig: firebase.remoteConfig.RemoteConfig
   analytics: firebase.analytics.Analytics
   FieldValue: any = null
   constructor(config: FirebaseConfig, useEmulator = false) {
      this.useEmulator = useEmulator
      firebase.initializeApp(config)
      this.auth = firebase.auth()
      this.db = firebase.firestore()
      this.storage = firebase.storage()
      if (useEmulator === true) {
         //console.log(`Using local emulator for authentication & storage`)
         //this.auth.useEmulator('http://localhost:9099')
         firebase.functions().useEmulator('localhost', 5000)
         //this.storage.useEmulator('localhost', 9199)
      }
      this.messaging = firebase.messaging.isSupported()
         ? firebase.messaging()
         : null
      this.analytics = firebase.analytics()
      this.remoteConfig = firebase.remoteConfig()
      this.remoteConfig.settings = {
         minimumFetchIntervalMillis:
            // refresh frequency for remote config
            process.env.NODE_ENV !== 'production' ? 1800000 : 3600000,
         fetchTimeoutMillis: 10000,
      }
      this.remoteConfig
         .ensureInitialized()
         .then(() => {
            console.debug('Firebase Remote Config is initialized')
            this.remoteConfig.fetchAndActivate()
         })
         .catch(err => {
            console.error('Firebase Remote Config failed to initialize', err)
         })
      // @see https://firebase.google.com/docs/firestore/manage-data/enable-offline
      //app.firestore().enablePersistence()

      this.FieldValue = firebase.firestore.FieldValue
   }

   bucket = (bucket: string) => firebase.app().storage(`gs://${bucket}`)

   // *** Auth API ***
   createProvider = (type: string) => new firebase.auth.OAuthProvider(type)

   signInWithPopup = async (provider: any) =>
      await firebase.auth().signInWithPopup(provider)

   doCreateUserWithEmailAndPassword = (email: any, password: any) =>
      this.auth.createUserWithEmailAndPassword(email, password)

   doSignInWithEmailAndPassword = (email: string, password: string) =>
      this.auth.signInWithEmailAndPassword(email, password)

   doSignOut = () => this.auth.signOut()

   doPasswordReset = (email: string) => this.auth.sendPasswordResetEmail(email)

   doPasswordUpdate = (password: string) => {
      if (this.auth.currentUser) {
         return this.auth.currentUser.updatePassword(password)
      }
   }

   config = (key: string) => this.remoteConfig.getValue(key)

   // *** Merge Auth and DB User API *** //
   onAuthUserListener = (next: any, fallback: any) =>
      this.auth.onAuthStateChanged((authUser: any) => {
         if (this.useEmulator !== true) {
            this.remoteConfig.fetchAndActivate()
         }
         if (authUser) {
            // Api.instance()
            //    .get('me')
            //    .then(async (res: any) => {
            //       const doc = res.data
            //       let role = null
            //       let dbUser: any = null
            //       if (!doc) {
            //          console.log(`incomplete user profile`)
            //          const { claims = {} } =
            //             (await this.auth.currentUser?.getIdTokenResult(true)) ||
            //             {}
            //          role = claims.userType
            //       } else {
            //          dbUser = doc
            //          role = dbUser.userType
            //       }

            //       if (!role) {
            //          return this.doSignOut()
            //       }
            this.user(authUser.uid)
               .then(async (doc: any) => {
                  let role = null
                  let dbUser: any = null
                  if (!doc.exists) {
                     console.log(`incomplete user profile`)
                     const { claims = {} } =
                        (await this.auth.currentUser?.getIdTokenResult(true)) ||
                        {}
                     role = claims.role
                  } else {
                     dbUser = doc.data()
                     role = dbUser.role
                  }

                  if (!role) {
                     return this.doSignOut()
                  }

                  this.role(role).then((role: any) => {
                     // merge auth and db user
                     authUser = {
                        uid: authUser.uid,
                        token: authUser.token,
                        email: authUser.email,
                        roleLabel: role.get('label'),
                        ...dbUser,
                     }
                     next(authUser)
                  })

                  // merge auth and db user
                  // authUser = {
                  //    uid: authUser.uid,
                  //    token: authUser.token,
                  //    refreshToken: authUser.refreshToken,
                  //    email: authUser.email,
                  //    ...dbUser,
                  //    role,
                  // }
               })
               .catch((e: any) =>
                  console.log(`Error fetching user data: ${e.message}`)
               )
         } else {
            fallback()
         }
      })

   // *** User API ***

   user = (uid: string) =>
      this.db
         .collection('users')
         .doc(uid)
         .get()

   role = (uid: string) =>
      this.db
         .collection('roles')
         .doc(uid)
         .get()
}

export default Firebase
