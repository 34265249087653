import Api from '../Api'
import Base64 from 'crypto-js/enc-base64'
import Utf8 from 'crypto-js/enc-utf8'

export const downloadUrl = path => `${Api.DEFAULT_URL}download/${path}`

export const download = (path, name = '', redirect = false) =>
   Api.instance()
      .get(
         `/dl/${encodeURIComponent(path)}/${encodeURIComponent(
            name,
         )}?redirect=${redirect}`,
         {
            headers: {
               'Content-Type': 'application/force-download',
            },
         },
      )
      .then(res => {
         if (redirect === false) {
            window.open(res.data.url)
         }
      })
      .catch(err => alert(err.message))

export const dlLink = (path, ref, redirect = false) =>
   Api.instance()
      .get(
         `/dl2/${encodeURIComponent(path)}/${encodeURIComponent(
            ref,
         )}?redirect=${redirect}`,
         {
            headers: {
               'Content-Type': 'application/force-download',
            },
         },
      )
      .then(res => {
         if (redirect === false) {
            window.open(res.data.url)
         }
      })
      .catch(err => alert(err.message))
/**
 * Retrieve a temporary public download URL for given resource
 * @param {String} path DB path of target document
 * @param {String} field Name of the field holding the file data (default: file)
 * @return {String} Public download URL (default TTL is 30mn)
 */
export const dl = async (path, field = 'file') =>
   Api.instance()
      .get(`/file/${Base64.stringify(Utf8.parse(`${path}|${field}`))}`)
      .then(res => alert(res.data.url))
