import React from 'react'
import FormComponent from '../Form'
import userInviteForm from './config/user-invite-form'
import { Container } from 'semantic-ui-react'
import { AutoHeader } from '../Common'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withFirebase } from '../Firebase'
import { withAuthentication } from '../Session'
import * as ROUTES from '../../constants/routes'
import uniqid from 'uniqid'
import labels from '../Common/translations'
import { labelHelper } from '../Helpers'
import { ErrorMessage } from '../Common'

class UserInvitePage extends React.Component {
   constructor(props) {
      super(props)
      this.props = props
      this.lb = new labelHelper(
         labels,
         this.props.authUser ? this.props.authUser.lang : labelHelper.lang,
      )
      this.state = { inviteError: false, dimmed: null }
   }

   onSubmit = data => {
      const onboardingKey = uniqid()
      this.setState({ dimmed: true })

      userInviteForm.actions
         .create({
            onboardingKey,
            firstname: '',
            lastname: '',
            invitedBy: {
               ref: `users/${this.props.authUser.uid}`,
               label: `${this.props.authUser.firstname} ${this.props.authUser.lastname}`,
            },
            ...data,
         })
         .then(response => {
            const url = `${window.location.origin}/onboarding/${response.data.uid}-${onboardingKey}`
            this.props.firebase.auth.sendSignInLinkToEmail(data.email, {
               url,
               handleCodeInApp: true,
            })
            this.props.history.push(ROUTES.USER)
         })
         .catch(err => {
            console.log(err)
            // throw new Error(err)
            // alert(this.lb._(labels.errors.errorInvitedUser || 'unknown'))
            // this.props.history.push(ROUTES.USER_INVITE)
            // return { inviteError: true }
            // window.location.reload()
            this.setState({ inviteError: true, dimmed: false })
         })
   }

   render() {
      return (
         <Container>
            <AutoHeader />
            {this.state.inviteError && (
               <ErrorMessage
                  content={this.lb._(labels.errors.errorInvitedUser)}
               />
            )}
            <FormComponent
               form={userInviteForm}
               onSubmit={this.onSubmit}
               isDimmed={this.state.dimmed}
               {...this.props}
            />
         </Container>
      )
   }
}

export default compose(
   withRouter,
   withFirebase,
   withAuthentication,
)(UserInvitePage)
