import React from 'react'
import { withAuthentication } from '../Session'
import { compose } from 'recompose'
import * as ROUTES from '../../constants/routes'
import { Switch, Route } from 'react-router-dom'
import { Container, Grid, Icon, Menu } from 'semantic-ui-react'
import TableComponent from '../Table'
import downloadsList from '../Downloads/config/downloads-list'
import templatesList from '../Templates/config/templates-list'
import { AutoHeader, AutoLabel, TranslatedButton } from '../Common'

const AdministrationPage = () => (
   <div>
      <Switch>
         <Route
            exact
            path={ROUTES.ADMINISTRATION}
            component={AdministrationMenu}
         />
      </Switch>
   </div>
)

class AdministrationMenu extends React.Component {
   state = {
      activeItem:
         sessionStorage.getItem('activeSubItemNavigation') || 'downloads',
      loading: false,
   }

   componentDidMount() {
      this.setState({ loading: true })
   }

   handleItemClick = (e: any, data: any) => {
      this.setState({ activeItem: data.name })
      sessionStorage.setItem('activeSubItemNavigation', data.name)
   }

   render() {
      const { activeItem } = this.state

      return (
         <Container>
            <Container>
               <Grid columns="equal">
                  <Grid.Column>
                     <Menu vertical inverted color="grey">
                        <Menu.Item
                           name="downloads"
                           active={activeItem === 'downloads'}
                           onClick={this.handleItemClick}
                        >
                           <Icon name="cloud download" />
                           <AutoLabel labelKey="screens.downloads" />
                        </Menu.Item>

                        <Menu.Item
                           name="templates"
                           active={activeItem === 'templates'}
                           onClick={this.handleItemClick}
                        >
                           <Icon name="file alternate outline" />
                           <AutoLabel labelKey="screens.templates" />
                        </Menu.Item>
                     </Menu>
                  </Grid.Column>
                  <Grid.Column>
                     <AutoHeader />
                  </Grid.Column>
                  <Grid.Column textAlign="right"></Grid.Column>
               </Grid>
            </Container>
            {activeItem === 'downloads' && (
               <Container>
                  <Grid>
                     <Grid.Column textAlign="right">
                        <TranslatedButton
                           link={ROUTES.DOWNLOAD_CREATE}
                           icon="file"
                           label="screens.downloads_create"
                        />
                     </Grid.Column>
                  </Grid>
                  <TableComponent list={downloadsList} {...this.props} />
               </Container>
            )}
            {activeItem === 'templates' && (
               <Container>
                  <Grid>
                     <Grid.Column textAlign="right">
                        <TranslatedButton
                           link={ROUTES.TEMPLATES_CREATE}
                           icon="file alternate outline"
                           label="screens.templates_create"
                        />
                     </Grid.Column>
                  </Grid>
                  <TableComponent list={templatesList} {...this.props} />
               </Container>
            )}
         </Container>
      )
   }
}

export default compose(withAuthentication)(AdministrationPage)
