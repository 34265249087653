import Api from '../../Api'
import { ROLES } from '../../../constants'
import FormComponent from '../../Form'
import statusValues from '../../../models/vecto-status'
import { Stakeholders } from '../../Common/'
import labels from '../../Common/translations'

export default {
   fields: {
      base: {
         type: FormComponent.GROUP,
         fields: {
            name: {
               label: labels.fields.name,
               mandatory: true,
            },
            mode: {
               label: labels.fields.vectorization_mode,
               values: [
                  {
                     key: 'fast',
                     label: labels.fields.fast_mode,
                  },
                  {
                     key: 'accurate.video',
                     label: labels.fields.accurate_mode_video,
                  },
                  {
                     key: 'accurate.image',
                     label: labels.fields.accurate_mode_image,
                  },
               ],
               options: {
                  defaultValue: 'accurate.video',
               },
               type: FormComponent.SELECT,
               mandatory: true,
            },
         },
      },
      parent: {
         type: FormComponent.GROUP,
         fields: {
            video: {
               label: labels.fields.video,
               return: 'object',
               mandatory: true,
               disabled: true,
               hidden: ({ authUser }) =>
                  !ROLES.ROLES_WITH_COMPANIES.includes(authUser.role),
               options: {
                  defaultValue: ({ video }) => ({
                     ref: `videos/${video.uid}`,
                     label: video.name,
                  }),
                  tabIndex: -1,
               },
            },
            videoFramerate: {
               label: labels.fields.framerate,
               disabled: true,
               type: FormComponent.NUMBER,
               options: {
                  label: { basic: true, content: 'fps' },
                  labelPosition: 'right',
                  defaultValue: ({ video }) => video && video.framerate,
                  tabIndex: -1,
               },
            },
            company: {
               label: labels.fields.company,
               return: 'object',
               mandatory: true,
               disabled: true,
               hidden: ({ authUser }) =>
                  !ROLES.ROLES_WITH_COMPANIES.includes(authUser.role),
               options: {
                  defaultValue: ({ video }, state) =>
                     video.company || state.item.company,
                  tabIndex: -1,
               },
            },
         },
      },
      offsets: {
         type: FormComponent.GROUP,
         fields: {
            framerate: {
               label: labels.fields.vecto_framerate,
               mandatory: true,
               type: FormComponent.NUMBER,
               disabled: false,
               options: {
                  label: { basic: true, content: 'fps' },
                  labelPosition: 'right',
                  defaultValue: ({ video }) => video && video.framerate,
                  min: '1',
                  max: '240',
               },
            },
            offsetStart: {
               label: labels.fields.start_offset,
               type: FormComponent.NUMBER,
               disabled: true,
               mandatory: true,
               options: {
                  label: { basic: true, content: 'sec' },
                  labelPosition: 'right',
               },
            },
            duration: {
               label: labels.fields.length_convert,
               mandatory: true,
               type: FormComponent.NUMBER,
               disabled: true,
               options: {
                  label: { basic: true, content: 'sec' },
                  labelPosition: 'right',
               },
            },
            offsetEnd: {
               label: labels.fields.end_offset,
               type: FormComponent.NUMBER,
               disabled: true,
               mandatory: true,
               options: {
                  label: { basic: true, content: 'sec' },
                  labelPosition: 'right',
               },
            },
         },
      },
      defaultAccessList: {
         label: labels.fields.default_access_list,
         type: FormComponent.SELECTMANY,
         mandatory: true,
         options: {
            defaultValue: ({ authUser }) => [`users/${authUser.uid}`],
         },
         values: {
            from: 'state',
            prop: 'defaultAccessListValues',
            source: ({ location }) =>
               Stakeholders(location.state.item.company.ref),
         },
      },
      status: {
         label: labels.fields.status,
         type: FormComponent.SELECT,
         hidden: true,
         values: statusValues,
         options: {
            defaultValue: 'new',
         },
      },
      createdBy: {
         label: labels.fields.creator,
         mandatory: true,
         disabled: true,
         hidden: true,
         options: {
            defaultValue: ({ authUser }) => ({
               ref: `users/${authUser.uid}`,
               label: `${authUser.firstname} ${authUser.lastname}`,
               role: authUser.role,
            }),
         },
      },
   },
   buttons: {
      delete: false,
      cancel: true,
      submit: true,
      extras: [],
   },
   actions: {
      create: data => Api.instance().post(`videos3d`, data),
      update: ({ uid, ...data }) =>
         Api.instance().patch(`${data.video.ref}/vectos/${uid}`, data),
   },
   extras: [],
   layouts: {
      default: [
         'name',
         ['video', 'company'],
         ['offsetStart', 'duration', 'offsetEnd'],
         'status',
         'createdBy',
      ],
   },
}
