import FormComponent from '../../Form'
import Api from '../../Api'
import * as ROLES from '../../../constants/roles'
import labels from '../../Common/translations'

export default {
   fields: {
      core: {
         type: FormComponent.GROUP,
         fields: {
            name: {
               label: labels.fields.name,
               mandatory: true,
               searchable: true,
            },
            company: {
               label: labels.fields.company,
               type: FormComponent.SELECT,
               values: {
                  from: 'state',
                  prop: 'companyValues',
                  collection: 'companies',
                  source: () =>
                     Api.selectValues('companies', { fsort: 'name' }),
               },
               return: FormComponent.FORMAT_OBJECT,
               mandatory: true,
               disabled: ({ authUser }, { data }) =>
                  (data.uid !== undefined && data.company !== '') ||
                  !ROLES.ROLES_WITH_COMPANIES.includes(authUser.role),
               options: {
                  defaultValue: ({ authUser }) =>
                     !ROLES.ROLES_WITH_COMPANIES.includes(authUser.role)
                        ? authUser.company
                        : '',
               },
            },
         },
      },
      params: {
         type: FormComponent.GROUP,
         fields: {
            resolution: {
               label: labels.fields.resolution,
               type: FormComponent.SELECT,
               mandatory: true,
               hidden: true,
               values: [
                  // { key: '1280x720', label: '1.2K - 1280 x 720' },
                  { key: '3840x1920', label: '4K - 3840 x 1920' },
                  { key: '5760x2880', label: '5.7K - 5760 x 2880' },
               ],
               options: {
                  defaultValue: '3840x1920',
               },
            },
            bitrate: {
               label: labels.fields.bitrate,
               type: FormComponent.NUMBER,
               mandatory: true,
               options: {
                  defaultValue: '100',
                  label: 'Mbps',
                  labelPosition: 'right',
               },
            },
            course: {
               label: labels.fields.course,
               type: FormComponent.SELECT,
               mandatory: true,
               values: [
                  {
                     key: 'off',
                     label: labels.fields.course_linear,
                  },
                  // {
                  //    key: 'on',
                  //    label: labels.fields.course_nonlinear,
                  // },
               ],
               options: {
                  defaultValue: 'off',
               },
            },
         },
      },
      options: {
         type: FormComponent.GROUP,
         fields: {
            generate5K: {
               type: FormComponent.CHECKBOX,
               label: labels.fields.generate_version,
               labelVariables: ['5.7K'],
            },
            deleteInsv: {
               type: FormComponent.CHECKBOX,
               label: labels.fields.delete_file_after,
               labelVariables: ['.insv'],
               options: {
                  defaultValue: true,
               },
            },
         },
      },

      insv: {
         type: FormComponent.MULTIFILE,
         minFiles: 2,
         maxFiles: 2,
         label: labels.fields.files,
         labelSuffix: '.insv',
         mandatory: true,
         options: {
            folder: ({ authUser }) => `${authUser.company.ref}/videos`,
            mimes: ['application/octet-stream', ''],
         },
      },
   },
   buttons: {
      delete: true,
      cancel: true,
      submit: true,
      extras: [],
   },
   actions: {
      create: data => Api.instance().post('videos360/job', data),
      read: uid => Api.instance().get(`videos360/${uid}`),
      update: ({ uid, ...data }) => Api.instance().patch('videos/' + uid, data),
      delete: ({ uid }) => Api.instance().delete('videos/' + uid),
   },
   extras: [],
}
