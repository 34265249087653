import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import {
   Container,
   Header,
   Button,
   Icon,
   Divider,
   Modal,
   Tab,
} from 'semantic-ui-react'
import FormComponent from '../Form'
import TableComponent from '../Table'
import versionsList from './config/versions-list'
import vectosList from './config/vectos-list'
import mediasList from './config/medias-list'
import envForm from './config/environment-form'
import { AutoLabel, EventsList } from '../Common'
import { withAuthentication } from '../Session'
import { ROUTES } from '../../constants'
import { TabWidget } from '../Widgets'
import { labelHelper } from '../Helpers'
import labels from '../Common/translations'

class EnvironmentShowPage extends React.Component {
   constructor(props) {
      super(props)
      this.state = { open: false }
      this.goBack = this.goBack.bind(this)
      this.modalRef = React.createRef()
   }

   lb = new labelHelper(
      labels,
      this.props.authUser ? this.props.authUser.lang : labelHelper.lang,
   )

   async componentDidMount() {
      this.setState({ loading: true })
      const { data: item } = await envForm.actions.read(
         this.props.match.params.id,
      )

      this.setState({
         item,
         loading: false,
      })
   }

   goBack() {
      this.props.history.push(ROUTES.ENVIRONMENTS)
   }

   accessList = () => {
      this.setState({ open: true })
   }

   close = () => this.setState({ open: false })

   panes = [
      {
         key: 'details',
         menuItem: this.lb._(labels.screens.detail),
         render: () => (
            <Tab.Pane key="details">
               <FormComponent
                  form={envForm}
                  readOnly={true}
                  data={this.state.item}
                  {...this.props}
               />
            </Tab.Pane>
         ),
      },
      {
         key: 'versions',
         menuItem: this.lb._(labels.screens.environment_show.versions),
         render: () => (
            <Tab.Pane key="versions">
               <TableComponent
                  title={
                     <AutoLabel labelKey="screens.environment_show.versions" />
                  }
                  list={versionsList}
                  baseRef={`environments/${this.state.item.uid}`}
                  modal={this.modalRef}
                  parent={this} // give access to page context so button can change state and display modal
                  {...this.props}
               />
            </Tab.Pane>
         ),
      },
      {
         key: 'scenes',
         menuItem: this.lb._(labels.screens.environment_show.usedVectos),
         render: () => (
            <Tab.Pane key="scenes">
               <TableComponent
                  title={
                     <AutoLabel labelKey="screens.environment_show.usedVectos" />
                  }
                  list={vectosList}
                  baseRef={`environments/${this.state.item.uid}`}
                  {...this.props}
               />
            </Tab.Pane>
         ),
      },
      {
         key: 'medias',
         menuItem: this.lb._(labels.screens.environment_show.usedMedias),
         render: () => (
            <Tab.Pane key="medias">
               <TableComponent
                  title={
                     <AutoLabel labelKey="screens.environment_show.usedMedias" />
                  }
                  list={mediasList}
                  baseRef={`environments/${this.state.item.uid}`}
                  {...this.props}
               />
            </Tab.Pane>
         ),
      },
      {
         key: 'event',
         menuItem: this.lb._(labels.fields.event),
         render: () => (
            <Tab.Pane key="event">
               <EventsList
                  labelKey="screens.relatedEvents"
                  hideObjectColumn={true}
                  condition={{
                     object: [
                        `environments/${this.state.item.uid}`,
                        '==',
                        'ref',
                     ],
                  }}
                  {...this.props}
               />
            </Tab.Pane>
         ),
      },
   ]

   render() {
      if (!this.state.item) {
         return <div />
      }

      const {
         item: { name },
      } = this.state
      return (
         <Container>
            <Container>
               <Header as="h1">{name}</Header>
               <Button primary icon labelPosition="left" onClick={this.goBack}>
                  <Icon name="left arrow" />
                  <AutoLabel labelKey="form.back" />
               </Button>
               <Divider hidden />
               <Container>
                  <Modal
                     open={this.state.open}
                     ref={this.modalRef}
                     closeIcon
                     content={this.state.content}
                     onClose={this.close}
                     closeOnEscape={false}
                     header={this.state.modalHeader}
                  />
               </Container>
               <TabWidget panes={this.panes} />
            </Container>
         </Container>
      )
   }
}

export default compose(withRouter, withAuthentication)(EnvironmentShowPage)
