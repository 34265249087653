import React from 'react'
import { withAuthorization, withAuthentication } from '../Session'
import { compose } from 'recompose'
import { Switch } from 'react-router-dom'
import UserCreatePage from './create'
import UserShowPage from './show'
import UserModifyPage from './modify'
import UserRecordPage from './record'
import UserInvitePage from './invite'
import { Container, Grid, Button } from 'semantic-ui-react'
import userList from './config/user-list'
import userSearch from './config/user-list-search'
import userInvitedList from './config/user-invited-list'
import userDeletedList from './config/user-deleted-list'
import {
   AutoHeader,
   TranslatedButton,
   AutoLabel,
   Page,
   MandatoryWarning,
} from '../Common'
import { pageKey } from '../Helpers'
import SearchComponent from '../Form/search'
import TableComponent from '../Table'
import { ROLES, ROUTES } from '../../constants'

const UserPage = () => (
   <div>
      <Switch>
         <Page
            path={ROUTES.USER_INVITE}
            component={UserInvitePage}
            title="screens.user_invite"
         />
         <Page
            path={ROUTES.USER_SHOW}
            component={UserShowPage}
            title="screens.user_show"
         />
         <Page
            path={ROUTES.USER_MODIFY}
            component={UserModifyPage}
            title="screens.user_modify"
         />
         <Page
            path={ROUTES.USER_RECORD}
            component={UserRecordPage}
            title="screens.user_record"
         />
         <Page
            path={ROUTES.USER_CREATE}
            component={UserCreatePage}
            title="screens.user_create"
         />
         <Page path={ROUTES.USER} component={UserList} title="screens.users" />
      </Switch>
   </div>
)

class UserListBase extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         loading: true,
         tableType: 'active-users',
      }
      this.storeKey = `${pageKey()}_filters`
   }

   /**
    * Set or get page shared components filters
    */
   filters = (filters = null) => {
      if (filters) {
         console.log('Setting new filters', filters)
         sessionStorage.setItem(this.storeKey, JSON.stringify(filters))
         this.indexVideos = 1 // reset page index
         this.setState({ filters })
      } else {
         return sessionStorage.getItem(this.storeKey)
            ? JSON.parse(sessionStorage.getItem(this.storeKey))
            : undefined
      }
   }

   render() {
      const { tableType } = this.state

      return (
         <Container>
            <Grid columns="equal">
               <Grid.Column>
                  <AutoHeader />
               </Grid.Column>
               <Grid.Column textAlign="right">
                  <TranslatedButton
                     link={ROUTES.USER_CREATE}
                     label="screens.user_create"
                     icon="user plus"
                  />
                  <TranslatedButton
                     link={ROUTES.USER_INVITE}
                     label="screens.user_invite"
                     icon="mail"
                     disabled
                  />
               </Grid.Column>
               <Grid.Row>
                  <Grid.Column textAlign="center">
                     <Button.Group>
                        <Button
                           toggle
                           positive={tableType === 'active-users'}
                           onClick={() =>
                              this.setState({ tableType: 'active-users' })
                           }
                        >
                           <AutoLabel labelKey="screens.user_switch_active" />
                        </Button>
                        <Button.Or />
                        <Button
                           toggle
                           positive={tableType === 'invite-users'}
                           onClick={() =>
                              this.setState({ tableType: 'invite-users' })
                           }
                        >
                           <AutoLabel labelKey="screens.user_switch_invite" />
                        </Button>
                        <Button.Or />
                        <Button
                           toggle
                           positive={tableType === 'deleted-users'}
                           onClick={() =>
                              this.setState({ tableType: 'deleted-users' })
                           }
                        >
                           <AutoLabel labelKey="screens.user_switch_deleted" />
                        </Button>
                     </Button.Group>
                  </Grid.Column>
               </Grid.Row>
            </Grid>
            <Container>
               <SearchComponent
                  form={userSearch}
                  data={this.filters()}
                  {...this.props}
                  propagate={filters => this.filters(filters)}
               />
            </Container>
            {this.shouldRender() && tableType === 'active-users' && (
               <TableComponent
                  list={userList}
                  filters={this.filters()}
                  {...this.props}
               />
            )}
            {this.shouldRender() && tableType === 'invite-users' && (
               <TableComponent
                  list={userInvitedList}
                  filters={this.filters()}
                  {...this.props}
               />
            )}
            {this.shouldRender() && tableType === 'deleted-users' && (
               <TableComponent
                  list={userDeletedList}
                  filters={this.filters()}
                  {...this.props}
               />
            )}
            {!this.shouldRender() && <MandatoryWarning />}
         </Container>
      )
   }

   /**
    * Should the content be rendered ?
    * if user is partner, they must choose a company
    */
   shouldRender() {
      return (
         (this.filters() && this.filters().company) ||
         [ROLES.ROLE_ROOT, ROLES.ROLE_ADMIN].includes(this.props.authUser.role)
      )
   }
}

const condition = authUser => true
// authUser && !!ROLES.ROLES_WITH_COMPANIES.includes(authUser.role)

const UserList = withAuthentication(UserListBase)

export default compose(withAuthorization(condition))(UserPage)
