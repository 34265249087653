import FormComponent from '../../Form'
import Api from '../../Api'
import * as ROUTES from '../../../constants/routes'
import { ROLE_ROOT } from '../../../constants/roles'
import labels from '../../Common/translations'

export default {
   fields: {
      core: {
         type: FormComponent.GROUP,
         fields: {
            email: {
               type: FormComponent.EMAIL,
               label: labels.fields.email_login,
               mandatory: true,
               searchable: true,
               options: {
                  autoComplete: 'email',
               },
            },
            lang: {
               label: labels.fields.language,
               type: FormComponent.SELECT,
               values: {
                  from: 'state',
                  prop: 'langValues',
                  collection: 'languages',
                  source: () => Api.selectValues('languages', {}),
               },
               return: FormComponent.FORMAT_DEFAULT,
               mandatory: true,
            },
         },
      },
      pro: {
         type: FormComponent.GROUP,
         fields: {
            company: {
               label: labels.fields.company,
               type: FormComponent.SELECT,
               values: {
                  from: 'state',
                  prop: 'companyValues',
                  collection: 'companies',
                  source: () =>
                     Api.selectValues('companies', { fsort: 'name' }),
               },
               return: FormComponent.FORMAT_OBJECT,
               mandatory: true,
               disabled: ({ authUser }) => authUser.role !== ROLE_ROOT,
               options: {
                  defaultValue: ({ authUser }) =>
                     authUser.role !== ROLE_ROOT ? authUser.company : '',
               },
            },
            role: {
               label: labels.fields.role,
               type: FormComponent.SELECT,
               values: {
                  from: 'state',
                  prop: 'roleValues',
                  collection: 'roles',
                  source: ({ authUser }) =>
                     Api.selectValues('roles', {
                        fsort: 'order',
                        q: {
                           access: [authUser.role, 'array-contains'],
                        },
                     }),
               },
               mandatory: true,
            },
            groups: {
               label: labels.fields.groups,
               type: FormComponent.SELECTMANY,
               mandatory: false,
               options: {
                  defaultValue: [],
               },
               observers: [
                  {
                     /**
                      * This observer loads the groups list of the active company
                      * acquired from the 'company' field
                      */
                     observable: 'company',
                     action: async data => {
                        if (!data.company || !data.company.ref) {
                           return { groupsValues: [] }
                        } else {
                           const items = await Api.selectValues(
                              `${data.company.ref}/groups`,
                              {
                                 fsort: 'name',
                              },
                           )
                           return {
                              groupsValues: items.map(
                                 ({ key, label: text }) => ({
                                    key,
                                    text,
                                    value: key,
                                 }),
                              ),
                           }
                        }
                     },
                  },
               ],
            },
         },
      },
   },
   buttons: {
      submit: {
         label: labels.fields.user_invite,
         icon: 'mail',
      },
      cancel: true,
      extras: [],
   },
   actions: {
      create: data =>
         Api.instance().post('user-invite', { origin: 'invitation', ...data }),
      redirect: ROUTES.USER,
   },
   extras: [],
}
