import { dateFormat, download, labelFormat } from '../../Helpers'
import Api from '../../Api'
import FormComponent from '../../Form'
import React from 'react'
import {
   Button,
   Container,
   Header,
   Icon,
   TextArea,
   Form,
} from 'semantic-ui-react'
import accessForm from './accesslist-form'
import delayForm from './delay-form'
import { ROLES, ROUTES } from '../../../constants'
import labels from '../../Common/translations'
import * as prettyBytes from 'pretty-bytes'

export default {
   endpoint: 'environments',
   pid: 'environment.ref',
   pagesize: 10,
   defaultSorting: { field: 'createdAt', order: 'desc' },
   columns: {
      createdBy: {
         label: labels.fields.created_by,
         accessor: s => s.createdBy.label,
      },
      company: {
         label: labels.fields.company,
         accessor: s => s.company.label,
      },
      revision: {
         label: labels.fields.revision,
         options: {
            maxWidth: '25px',
         },
      },
      size: {
         label: labels.fields.size,
         accessor: s => prettyBytes(parseInt(s.size || 0)),
      },
      updatedAt: {
         label: labels.fields.latest_updated,
         accessor: s => dateFormat(s.updatedAt, true),
      },
      status: {
         label: labels.fields.status,
         accessor: s => labelFormat(s.status, labels.statuses.environment),
      },
   },
   buttons: {
      download: {
         disabled: data => data.status === 'pending',
         hidden: (data, { authUser }) => authUser.role !== ROLES.ROLE_ROOT,
         action: async (data, _props, _rowInfo, e) => {
            e.persist()
            const origCN = e.target.className
            e.target.className = 'spinner loading icon disabled'
            await download(data.file.ref)
            e.target.className = origCN
         },
         label: labels.fields.downloads,
         options: {
            icon: 'download',
            primary: true,
         },
      },
      treeview: {
         disabled: data => data.status === 'pending',
         label: labels.fields.show,
         url: (row, props) => {
            // get environment id from page props
            return ROUTES.ENVIRONMENTS_TREEVIEW.replace(
               ':pid',
               props.match.params.id
            ).replace(':id', row.uid)
         },
         options: {
            icon: 'street view',
            positive: true,
         },
      },
      access: {
         disabled: data => data.status !== 'completed',
         // display the contextual access list form in the screen modal
         // update the update action to provide the matching path
         action: async (data, props) => {
            accessForm.actions.update = ({ uid, ...data }) =>
               Api.instance().patch(`${props.baseRef}/versions/${uid}`, data)

            // open modal with contextual form content
            props.parent.setState({
               open: true,
               modalHeader: 'Access List Management',
               content: (
                  <Container>
                     <Header as="h4" block>
                        {`Version ${data.createdBy.label} (${data.company.label})`}
                     </Header>
                     <FormComponent form={accessForm} data={data} {...props} />
                  </Container>
               ),
            })
         },
         label: labels.fields.access_list,
         options: {
            icon: 'lock',
            primary: true,
         },
      },
      log: {
         disabled: data => data.status !== 'completed',
         action: async (data, props) => {
            Api.instance()
               .get(`${props.baseRef}/versions/${data.uid}/log`)
               .then(({ data: logData }) => {
                  // open modal with contextual form content
                  props.parent.setState({
                     open: true,
                     modalHeader: 'Latest Processing Log',
                     content: (
                        <Container>
                           <Header as="h4" block>
                              {`Version ${data.createdBy.label} | ${dateFormat(
                                 logData.createdAt,
                                 false
                              )}`}
                           </Header>
                           <Form>
                              <TextArea
                                 style={{
                                    minHeight: 100,
                                    fontFamily: 'Courier',
                                    fontSize: '9pt',
                                 }}
                                 rows={40}
                              >
                                 {logData.log}
                              </TextArea>
                           </Form>
                        </Container>
                     ),
                  })
               })
         },
         label: labels.fields.log,
         options: {
            icon: 'align left',
            primary: true,
         },
      },
      delete: {
         hidden: data => data.status === 'deletable',
         action: async (data, props) => {
            // form is designed for an object update even if we want to perform a delete
            delayForm.actions.update = ({ uid, ...data }) =>
               Api.instance().delete(`${props.baseRef}/versions/${uid}`, data)
            props.parent.setState({
               open: true,
               modalHeader: 'Version deletion',
               content: (
                  <Container>
                     <Header as="h4" block>
                        {`Version ${data.createdBy.label} (${data.company.label})`}
                     </Header>
                     <FormComponent form={delayForm} data={data} {...props} />
                  </Container>
               ),
            })
         },
         label: labels.fields.delete,
         options: {
            icon: 'trash alternate',
            negative: true,
         },
      },
      restore: {
         hidden: (data, { authUser }) =>
            data.status !== 'deletable' || authUser.role !== ROLES.ROLE_ROOT,
         action: async ({ uid, createdBy }, { baseRef, parent }) => {
            parent.setState({
               open: true,
               modalHeader: `Environment's version restore`,
               content: (
                  <Container style={{ padding: '15px', margin: '15px' }}>
                     <Header>{`Please confirm immediate restoration of environment ${parent.state.item.name}'s version '${createdBy.label}'`}</Header>
                     <Button
                        icon
                        labelPosition="left"
                        onClick={() =>
                           Api.instance()
                              .patch(`${baseRef}/versions/${uid}/restore`)
                              .then(() => {
                                 parent.setState({ open: false })
                                 window.location.reload(false)
                              })
                        }
                        positive
                     >
                        <Icon name="backward" />
                        Restore
                     </Button>
                     <Button
                        icon
                        labelPosition="left"
                        onClick={() => parent.setState({ open: false })}
                     >
                        <Icon name="cancel" />
                        Cancel
                     </Button>
                  </Container>
               ),
            })
         },
         label: labels.fields.restore,
         options: {
            icon: 'backward',
            color: 'orange',
         },
      },
   },
   actions: {
      list: (params, props) =>
         Api.instance().get(`${props.baseRef}/versions`, params),
   },
}
