import React from 'react'
import { Switch } from 'react-router-dom'
import { withAuthentication } from '../Session'
import { ROUTES } from '../../constants'
import {
   Container,
   Divider,
   Grid,
   Header,
   Modal,
   Label,
   Icon,
   Menu,
} from 'semantic-ui-react'
import TableComponent from '../Table'
import companyForm from './config/company-admin-form'
import { AutoLabel, Page, TranslatedButton } from '../Common'
import FormComponent from '../Form'
import companyGroupsList from './config/group/list'
import { dureeFormat } from '../Helpers'

const CompanyAdminPage = () => (
   <Switch>
      <Page
         exact
         path={ROUTES.COMPANY_ADMIN}
         component={CompanyAdmin}
         title="screens.company_title"
      />
   </Switch>
)

class CompanyAdminBase extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         loading: false,
         item: null,
         open: false,
         modalHeader: '',
      }
   }

   async componentDidMount() {
      this.setState({
         loading: true,
         item: await companyForm.actions
            .read(this.props.authUser.company.ref)
            .then(({ data }) => data),
      })
   }

   render() {
      if (!this.state.item) {
         return <div />
      }
      const { item } = this.state
      return (
         <Container>
            <Grid columns="equal">
               <Grid.Column>
                  <Header as="h1">{item.name}</Header>
               </Grid.Column>
               <Grid.Column width={8}>
                  <Menu compact>
                     <Menu.Item as="a">
                        <Icon name="users" />
                        <AutoLabel labelKey="fields.users_total" />
                        <Label color="green" floating>
                           {item.users.total}
                           {item.users.purchased > 0 &&
                              `/${item.users.purchased}`}
                        </Label>
                     </Menu.Item>
                     <Menu.Item>
                        <Icon name="user circle" />
                        <AutoLabel labelKey="fields.users_active" />
                        <Label color="orange" floating>
                           {item.users.active}
                           {item.users.maxActive > 0 &&
                              `/${item.users.maxActive}`}
                        </Label>
                     </Menu.Item>
                     <Menu.Item>
                        <Icon name="hourglass" />
                        <AutoLabel labelKey="fields.minutes_total" />
                        <Label color="green" floating>
                           {item.seconds.purchased
                              ? Math.round(item.seconds.purchased / 60)
                              : '...'}
                        </Label>
                     </Menu.Item>
                     <Menu.Item>
                        <Icon name="hourglass half" />
                        <AutoLabel labelKey="fields.minutes_used" />
                        <Label color="orange" floating>
                           {dureeFormat(item.seconds.used * 1000, true)}
                        </Label>
                     </Menu.Item>
                  </Menu>
               </Grid.Column>
               <Grid.Column textAlign="right">
                  <TranslatedButton
                     link={ROUTES.COMPANY_GROUP_CREATE.replace(
                        ':id',
                        this.props.authUser.company.ref.split('/').pop()
                     )}
                     icon="group"
                     label="screens.company_group_create"
                  />
               </Grid.Column>
            </Grid>
            <Divider hidden />
            <Container>
               <FormComponent
                  form={companyForm}
                  data={this.state.item}
                  {...this.props}
               />
               <Divider hidden />
               <Modal
                  open={this.state.open}
                  ref={this.modalRef}
                  closeIcon
                  content={this.state.content}
                  onClose={() => this.setState({ open: false })}
                  closeOnEscape={false}
                  header={this.state.modalHeader}
               />
               <TableComponent
                  title="labels.fields.groups"
                  list={companyGroupsList}
                  baseRef={this.props.authUser.company.ref}
                  company={item}
                  parent={this}
                  modalHeader={<AutoLabel labelKey="screens.guests_invite" />}
                  {...this.props}
               />
            </Container>
         </Container>
      )
   }
}

const CompanyAdmin = withAuthentication(CompanyAdminBase)

export default CompanyAdminPage
