import { DateTime } from 'luxon'
import { labelHelper } from '.'

export const dateFormat = (ts = 0, time = DateTime.DATETIME_MED) => {
   if (!ts) {
      return 'N/A'
   }

   if (typeof ts === 'object') {
      // date is stored as firestore timestamp
      ts = ts._seconds * 1000 + ts._nanoseconds / 1000
   }

   let date = isNaN(ts) ? DateTime.fromISO(ts) : DateTime.fromMillis(ts)
   date.setZone('Europe/Paris')
   return !time && DateTime.local().minus({ days: 2 }) < date
      ? date.toRelative({ locale: labelHelper.lang })
      : date.toLocaleString(time ? time : DateTime.DATE_MED)
}
