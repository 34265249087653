import React from 'react'
import { withAuthentication } from '../Session'
import * as ROUTES from '../../constants/routes'
import * as ROLES from '../../constants/roles'
import { Switch } from 'react-router-dom'
import CompanyShowPage from './show'
import CompanyCreatePage from './create'
import CompanyModifyPage from './modify'
import CompanyCreditsPage from './company-purchases'
import CompanyGroupsPage from './company-groups'
import { Container, Grid } from 'semantic-ui-react'
import TableComponent from '../Table'
import companiesList from './config/company-list'
import companyForm from './config/company-admin-form'
import companySearch from './config/company-search'
import SearchComponent from '../Form/search'
import { pageKey } from '../Helpers'
import { AutoHeader, TranslatedButton, Page } from '../Common'
import companyGroupCreatePage from './group/create'
import companyGroupModifyPage from './group/modify'
import companyPurchaseCreatePage from './purchases/create'

const CompanyPage = () => (
   <Switch>
      <Page
         exact
         path={ROUTES.COMPANY}
         component={CompanyList}
         title="screens.company_title"
      />
      <Page
         path={ROUTES.COMPANY_SHOW}
         component={CompanyShowPage}
         title="screens.company_show"
      />
      <Page
         path={ROUTES.COMPANY_CREATE}
         component={CompanyCreatePage}
         title="screens.company_create"
      />
      <Page
         path={ROUTES.COMPANY_MODIFY}
         component={CompanyModifyPage}
         title="screens.company_modify"
      />
      <Page
         path={ROUTES.COMPANY_GROUPS}
         component={CompanyGroupsPage}
         title="screens.company_groups"
      />
      <Page
         path={ROUTES.COMPANY_PURCHASES}
         component={CompanyCreditsPage}
         title="screens.company_purchases"
      />
      <Page
         path={ROUTES.COMPANY_PURCHASE_CREATE}
         component={companyPurchaseCreatePage}
         title="screens.company_purchase_create"
      />
      <Page
         path={ROUTES.COMPANY_GROUP_CREATE}
         component={companyGroupCreatePage}
         title="screens.company_group_create"
      />
      <Page
         path={ROUTES.COMPANY_GROUP_MODIFY}
         component={companyGroupModifyPage}
         title="screens.company_group_modify"
      />
   </Switch>
)

class CompanyListBase extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         loading: false,
         canCreateCompanies: false,
         item: null,
      }
      this.indexCompanies = parseInt(
         sessionStorage.getItem('index-companies'),
         10
      )
      this.storeKey = `${pageKey()}_filters`
   }

   async componentDidMount() {
      this.setState({
         loading: true,
         cantCreateCompanies: await this.cantCreateCompanies(),
         item: await companyForm.actions
            .read(this.props.authUser.company.ref)
            .then(({ data }) => data),
      })
   }

   /**
    * Set or get page shared components filters
    */
   filters = (filters = null) => {
      if (filters) {
         console.log('Setting new filters', filters)
         sessionStorage.setItem(this.storeKey, JSON.stringify(filters))
         this.indexVideos = 1 // reset page index
         this.setState({ filters })
      } else {
         return sessionStorage.getItem(this.storeKey)
            ? JSON.parse(sessionStorage.getItem(this.storeKey))
            : undefined
      }
   }

   cantCreateCompanies = async () => {
      const { authUser } = this.props
      if (authUser.role === ROLES.ROLE_ROOT) {
         return false
      }

      if (authUser.role === ROLES.ROLE_PARTNER) {
         const { data } = await companyForm.actions.read(authUser.company.ref)
         if (data.isPartner === true && data.canCreateCompanies === true) {
            return false
         }
      }

      return true
   }

   /**
    * Set or get page shared components filters
    */
   filters = (filters = null) => {
      if (filters) {
         console.log('Setting new filters', filters)
         sessionStorage.setItem(this.storeKey, JSON.stringify(filters))
         this.indexVideos = 1 // reset page index
         this.setState({ filters })
      } else {
         return sessionStorage.getItem(this.storeKey)
            ? JSON.parse(sessionStorage.getItem(this.storeKey))
            : undefined
      }
   }

   render() {
      if (!ROLES.ROLES_WITH_COMPANIES.includes(this.props.authUser.role)) {
         return <div>Accès interdit</div>
      }

      return (
         <Container>
            <Grid columns="equal">
               <Grid.Column>
                  <AutoHeader />
               </Grid.Column>
               <Grid.Column textAlign="right">
                  <TranslatedButton
                     link={ROUTES.COMPANY_CREATE}
                     icon="building"
                     label="screens.company_create"
                     disabled={this.state.cantCreateCompanies}
                  />
               </Grid.Column>
            </Grid>
            <SearchComponent
               form={companySearch}
               data={this.filters()}
               {...this.props}
               propagate={filters => this.filters(filters)}
            />
            <TableComponent
               list={companiesList}
               index={this.indexCompanies}
               filters={this.filters()}
               {...this.props}
               propagate={filters => this.filters(filters)}
            />
         </Container>
      )
   }

   /**
    * Should the content be rendered ?
    * if user is partner, they must choose a company
    */
   shouldRender() {
      return (
         (this.filters() && this.filters().company) ||
         [ROLES.ROLE_ROOT, ROLES.ROLE_ADMIN].includes(this.props.authUser.role)
      )
   }
}

const CompanyList = withAuthentication(CompanyListBase)

export default CompanyPage
