import { dureeFormat, dateFormat, labelFormat } from '../../Helpers'
import Api from '../../Api'
import vectoStatus from '../../../models/vecto-status'
import * as ROUTES from '../../../constants/routes'
import labels from '../../Common/translations'

export default {
   endpoint: 'videos/*/vectos',
   pid: 'video.ref',
   pagesize: 10,
   defaultSorting: { field: 'status', order: 'desc' },
   q: {
      status: 'completed',
   },
   columns: {
      name: {
         label: labels.fields.name,
         accessor: s => `${s.name}`,
      },
      company: {
         label: labels.fields.company,
         accessor: s => `${s.company ? s.company.label : ''}`,
         hidden: ({ authUser }) => authUser.role === 'admin',
      },
      duration: {
         label: labels.fields.length,
         accessor: s => dureeFormat(s.duration * 1000, true),
      },
      status: {
         label: labels.fields.status,
         accessor: s => labelFormat(s.status, vectoStatus),
      },
      updatedAt: {
         label: labels.fields.updatedAt,
         accessor: s => dateFormat(s.updatedAt),
      },
      createdAt: {
         label: labels.fields.created_at,
         accessor: row => dateFormat(row.createdAt),
      },
   },
   buttons: {
      show: {
         label: labels.fields.show,
         url: ROUTES.SCENE_SHOW,
         options: {
            icon: 'eye',
            positive: true,
         },
      },
   },
   actions: {
      list: params => {
         return Api.instance().get('videos3d', params)
      },
   },
}
