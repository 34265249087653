import FormComponent from '../../Form'
import Api from '../../Api'
import { ROLES } from '../../../constants'
import labels from '../../Common/translations'

export default {
   fields: {
      base: {
         type: FormComponent.GROUP,
         fields: {
            name: {
               label: labels.fields.name,
               mandatory: true,
            },
            isPartner: {
               label: labels.fields.company_partner,
               type: FormComponent.CHECKBOX,
               hidden: ({ authUser }) => authUser.role !== ROLES.ROLE_ROOT,
               options: {
                  defaultValue: false,
               },
            },
            canCreateCompanies: {
               label: labels.fields.can_create_company,
               type: FormComponent.CHECKBOX,
               disabled: (_, { data }) => data.isPartner !== true,
               hidden: ({ authUser }) => authUser.role !== ROLES.ROLE_ROOT,
               options: {
                  defaultValue: false,
               },
            },
         },
      },
      offline: {
         type: FormComponent.GROUP,
         fields: {
            offlinePrivilege: {
               label: labels.fields.offline_access,
               type: FormComponent.CHECKBOX,
               options: {
                  defaultValue: false,
               },
            },
            // offlineEnabled: {
            //    label: labels.fields.activate_offline_mode,
            //    type: FormComponent.CHECKBOX,
            //    disabled: (_, { data }) => data.offlinePrivilege !== true,
            //    options: {
            //       defaultValue: false,
            //    },
            // },
            offlineDuration: {
               label: labels.fields.offline_file_lifetime,
               disabled: (_, { data }) => data.offlinePrivilege !== true,
               options: {
                  defaultValue: 5,
                  label: { basic: true, content: labels.units.days },
                  labelPosition: 'right',
               },
            },
         },
      },
      partnerData: {
         label: labels.fields.partner_information,
         type: FormComponent.GROUP,
         fields: {
            partner: {
               label: labels.fields.partner,
               type: FormComponent.SELECT,
               hidden: ({ authUser }) => authUser.role !== ROLES.ROLE_ROOT,
               values: {
                  from: 'state',
                  prop: 'partnerValues',
                  collection: 'companies',
                  source: () =>
                     Api.selectValues('companies', {
                        q: { isPartner: [true] },
                        fsort: 'name',
                     }),
               },
               return: 'object',
            },
            allowPartnerAccess: {
               label: labels.fields.allow_partner_access,
               type: FormComponent.CHECKBOX,
               disabled: ({ authUser }) =>
                  ![ROLES.ROLE_ROOT, ROLES.ROLE_ADMIN].includes(authUser.role),
               options: {
                  defaultValue: true,
               },
            },
         },
      },
      authentication: {
         label: labels.fields.third_place_authentication,
         type: FormComponent.GROUP,
         fields: {
            authFqdn: {
               label: labels.fields.domain_name,
            },
            role: {
               label: labels.fields.default_role,
               type: FormComponent.SELECT,
               values: {
                  from: 'state',
                  prop: 'roleValues',
                  collection: 'roles',
                  source: () =>
                     Api.selectValues('roles', {
                        fsort: 'order',
                     }),
               },
            },
         },
      },
   },
   buttons: {
      delete: true,
      submit: true,
      cancel: true,
      extras: [],
   },
   actions: {
      create: data => Api.instance().post('companies/', data),
      read: ref => Api.instance().get(ref),
      update: ({ uid, ...data }) =>
         Api.instance().patch(`companies/${uid}`, data),
      delete: ({ uid }) => Api.instance().delete(`companies/${uid}`),
   },
   extras: [],
}
