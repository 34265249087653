import * as ROUTES from '../../../constants/routes'
import Api from '../../Api'
import delayForm from './delay-form'
import FormComponent from '../../Form'
import React from 'react'
import {
   Button,
   Card,
   Container,
   Header,
   Icon,
   Image,
   Popup,
   List,
} from 'semantic-ui-react'
import { ROLES } from '../../../constants'
import labels from '../../Common/translations'
import * as prettyBytes from 'pretty-bytes'
import { dateFormat } from '../../Helpers'

export default {
   endpoint: 'medias',
   pagesize: 9,
   defaultSorting: { field: 'createdAt', order: 'desc' },
   columns: 3,
   renderer: async (data, { firebase }, state) => {
      let url
      if (data.thumbRef256) {
         url = await firebase.storage.ref(data.thumbRef256).getDownloadURL()
      } else if (data.thumbRef128) {
         url = await firebase.storage.ref(data.thumbRef128).getDownloadURL()
      } else if (data.filethumb) {
         url = await firebase.storage.ref(data.filethumb).getDownloadURL()
      } else {
         if (data.contentType === 'application/pdf') {
            url = await firebase.storage.ref('pdf_file.png').getDownloadURL()
         } else {
            url = await firebase.storage.ref('unknown.png').getDownloadURL()
         }
      }
      return (
         <Popup
            trigger={
               <Card key={data.uid} align="center">
                  <Image src={url} size={state.size} wrapped ui={false} />
                  <Card.Content>
                     <Card.Header>
                        {data.name.length > 28
                           ? `${data.name.substring(0, 25)}...`
                           : data.name}
                     </Card.Header>
                     <Card.Content extra>
                        <Icon name="factory" />
                        {data.company.label}
                     </Card.Content>
                  </Card.Content>
               </Card>
            }
         >
            <Popup.Content>
               <List>
                  <List.Item>
                     <List.Icon name="file" />
                     <List.Content>{data.contentType}</List.Content>
                  </List.Item>
                  <List.Item>
                     <List.Icon name="weight" />
                     <List.Content>
                        {prettyBytes(parseInt(data.size || 0))}
                     </List.Content>
                  </List.Item>
                  <List.Item>
                     <List.Icon name="user" />
                     <List.Content>{data.createdBy.label}</List.Content>
                  </List.Item>
                  <List.Item>
                     <List.Icon name="wait" />
                     <List.Content>
                        {dateFormat(data.createdAt)} CET
                     </List.Content>
                  </List.Item>
                  <List.Item>
                     <List.Icon name="clock outline" />
                     <List.Content>
                        {dateFormat(data.updatedAt)} CET
                     </List.Content>
                  </List.Item>
                  <List.Item>
                     <List.Icon name="share alternate" />
                     <List.Content>{data.shares || 0}</List.Content>
                  </List.Item>
                  <List.Item>
                     <List.Icon name="tag" />
                     <List.Content>
                        {data.tags ? data.tags.join(' | ') : '-'}
                     </List.Content>
                  </List.Item>
               </List>
            </Popup.Content>
         </Popup>
      )
   },
   buttons: {
      show: {
         label: labels.fields.show,
         url: ROUTES.ENVIRONMENTS_SHOW,
         options: {
            icon: 'eye',
            positive: true,
         },
      },
      delete: {
         hidden: (data, { authUser }) =>
            data.status === 'deletable' || authUser.role !== ROLES.ROLE_ROOT,
         action: async (data, props) => {
            // form is designed for an object update even if we want to perform a delete
            delayForm.actions.update = ({ uid, ...data }) =>
               Api.instance().delete(`/medias/${uid}`, data)
            props.parent.setState({
               open: true,
               modalHeader: 'Media deletion',
               content: (
                  <Container>
                     <Header as="h4" block>
                        {`Media ${data.name} (${data.company.label})`}
                     </Header>
                     <FormComponent form={delayForm} data={data} {...props} />
                  </Container>
               ),
            })
         },
         label: labels.fields.delete,
         options: {
            icon: 'trash alternate',
            negative: true,
         },
      },
      restore: {
         hidden: (data, { authUser }) =>
            data.status !== 'deletable' || authUser.role !== ROLES.ROLE_ROOT,
         action: async ({ uid, name }, props) => {
            props.parent.setState({
               open: true,
               modalHeader: 'Media restore',
               content: (
                  <Container style={{ padding: '15px', margin: '15px' }}>
                     <Header>{`Please confirm media '${name}' immediate restoration`}</Header>
                     <Button
                        icon
                        labelPosition="left"
                        onClick={() =>
                           Api.instance()
                              .patch(`/medias/${uid}/restore`)
                              .then(() => {
                                 props.parent.setState({ open: false })
                                 window.location.reload(false)
                              })
                        }
                        positive
                     >
                        <Icon name="backward" />
                        Restore
                     </Button>
                     <Button
                        icon
                        labelPosition="left"
                        onClick={() => props.parent.setState({ open: false })}
                     >
                        <Icon name="cancel" />
                        Cancel
                     </Button>
                  </Container>
               ),
            })
         },
         label: labels.fields.restore,
         options: {
            icon: 'backward',
            color: 'orange',
         },
      },
   },
   actions: {
      list: params => {
         return Api.instance().get('medias', params)
      },
   },
}
